type MessageType =
  | "redirect"
  | "openModal"
  | "openPopup"
  | "closePopup"
  | "resize"
  | "changeQueryString"
  | "general"
  | "takeScreenshot";
interface MessageOptions {
  command: MessageType;
  message: string;
  groupId?: number;
  orgId?: number;
  newTab?: boolean;
}

const postMessageToParent = (options: MessageOptions) => {
  if (window !== window.parent) {
    window.parent.postMessage(
      {
        source: "tsd",
        ...options,
      },
      import.meta.env.VITE_TSD_LEGACY_PURCHASING_SERVER_BASE!
    );
  }
};

export default postMessageToParent;
