import createTsdApi from "../../../client-api/createTsdApi";
import {
  PrebuiltOrderCustomer,
  PrebuiltOrderCustomerQueryParams,
  PrebuiltOrderCustomerCreateParams,
  PrebuiltOrderCustomerUpdateParams,
} from "../../../entities/prebuiltOrderCustomer";
import { TsdSearchParam } from "../fetch";

const prebuiltOrderCustomerApi = createTsdApi<
  PrebuiltOrderCustomer,
  PrebuiltOrderCustomerQueryParams,
  PrebuiltOrderCustomerCreateParams,
  PrebuiltOrderCustomerUpdateParams
>("prebuilt-order-customers", (searchParamsObject) => {
  let searchParams: TsdSearchParam<PrebuiltOrderCustomerQueryParams>[] = [];

  ({
    customerId: (() => {
      if (typeof searchParamsObject.customerId !== "undefined") {
        searchParams.push({
          key: "customerId",
          value: String(searchParamsObject.customerId),
        });
      }
    })(),
    customerIds: (() => {
      if (typeof searchParamsObject.customerIds !== "undefined") {
        searchParamsObject.customerIds.forEach((id) => {
          searchParams.push({
            key: "customerIds",
            value: String(id),
          });
        });
      }
    })(),
    prebuiltOrderId: (() => {
      if (typeof searchParamsObject.prebuiltOrderId !== "undefined") {
        searchParams.push({
          key: "prebuiltOrderId",
          value: String(searchParamsObject.prebuiltOrderId),
        });
      }
    })(),
    prebuiltOrderIds: (() => {
      if (typeof searchParamsObject.prebuiltOrderIds !== "undefined") {
        searchParamsObject.prebuiltOrderIds.forEach((id) => {
          searchParams.push({
            key: "prebuiltOrderIds",
            value: String(id),
          });
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<PrebuiltOrderCustomerQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default prebuiltOrderCustomerApi;
