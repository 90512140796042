import Cookie from "js-cookie";
import {
  PaginationParams,
  PaginationResponse,
} from "../../entities/Pagination/pagination";

export type TsdSearchParam<T> = {
  key: keyof T;
  value: string;
};

export interface IMapFunction<ObjectType> {
  (searchParamsObject: ObjectType): TsdSearchParam<ObjectType>[];
}

export interface ITsdFetchResponse<T> extends Response {
  json: () => Promise<T>;
}

export interface ITsdFetchInit extends RequestInit {
  headers?: { [key: string]: string };
}

export type ITsdFetch<T> = Promise<ITsdFetchResponse<T>>;

export type RowCount = {
  rowCount: number;
};
export type GetAllParams<T> = T & PaginationParams;
export type GetAllFunc<T, G> = (
  params: GetAllParams<G>
) => ITsdFetch<PaginationResponse<T>>;

// export type GetAllFunc<T, G> = (
//   params: GetAllParams<G>
// ) => ITsdFetch<
//   GetAllParams<G>["returnRowCount"] extends true ? RowCount : T[]
// >;

const tsdFetch: <T, G>(
  endpoint: string,
  init: ITsdFetchInit,
  searchParams?: TsdSearchParam<G>[]
) => ITsdFetch<T> = async (endpoint, init, searchParams) => {
  const processServerBase =
    (typeof process !== "undefined" &&
      process.env &&
      process.env.TSD_ACCOUNTS_SERVER_BASE) ||
    "";

  const windowServerBase =
    // @ts-ignore
    typeof window !== "undefined" && window.TSD_API_SERVER_BASE
      ? // @ts-ignore
        window.TSD_API_SERVER_BASE
      : "";

  const baseUrl = new URL(processServerBase || windowServerBase);

  const url = new URL(endpoint.replace(/^\/+/g, ""), baseUrl);

  const token =
    Cookie.get("jwt") ??
    (typeof process !== "undefined" ? process.env.TSD_API_JWT : "");

  const bearer = `Bearer ${token}`;

  if (searchParams) {
    searchParams.forEach((searchParam) => {
      url.searchParams.append(searchParam.key as string, searchParam.value);
    });
  }

  let forceRetry = false;
  const maxTries = !init || !init.method || init.method === "GET" ? 10 : 1;
  let currentTries = 0;
  let response: ITsdFetchResponse<any> | undefined;
  const delayMs = 1000;
  const addDelayMs = 400;
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  while (currentTries < maxTries || forceRetry) {
    try {
      response = await fetch(url.href, {
        ...init,
        headers: {
          ...{
            ...(typeof init.headers !== "undefined" &&
            typeof init.headers["Content-Type"] !== "undefined"
              ? { "Content-Type": init.headers["Content-Type"] }
              : {}),
          },
          ...init.headers,
          ...(token ? { Authorization: bearer } : {}),
        },
      });

      if (response.status >= 500 || response.status === 429) {
        currentTries++;
        await delay(addDelayMs * currentTries + delayMs);
      } else {
        break;
      }
    } catch (e) {
      if (e instanceof TypeError) {
        const { cause } = e;

        if (
          typeof cause === "object" &&
          cause !== null &&
          "syscall" in cause &&
          "code" in cause &&
          "errno" in cause &&
          cause.syscall === "write" &&
          cause.code === "EPIPE" &&
          cause.errno === -32
        ) {
          forceRetry = true;
        } else {
          forceRetry = false;
        }
      }

      currentTries++;
      await delay(addDelayMs * currentTries + delayMs);
    }
  }

  if (response === undefined) {
    throw new Error("Failed to fetch");
  }
  return response;
};

export default tsdFetch;
