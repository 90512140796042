import dayjs from "dayjs";
import createTsdApi from "../../../client-api/createTsdApi";
import {
  Discount,
  DiscountCreateParams,
  DiscountQueryParams,
  DiscountUpdateParams,
} from "../../../entities/discount";
import { TsdSearchParam } from "../fetch";

const discountApi = createTsdApi<
  Discount,
  DiscountQueryParams,
  DiscountCreateParams,
  DiscountUpdateParams
>("discounts", (params) => {
  const searchParams: TsdSearchParam<DiscountQueryParams>[] = [];

  ({
    notIds: (() => {
      if (typeof params.notIds !== "undefined") {
        params.notIds.forEach((id) => {
          searchParams.push({
            key: "notIds",
            value: String(id),
          });
        });
      }
    })(),
    orgIds: (() => {
      if (typeof params.orgIds !== "undefined") {
        params.orgIds.forEach((id) => {
          searchParams.push({
            key: "orgIds",
            value: String(id),
          });
        });
      }
    })(),
    isActive: (() => {
      if (typeof params.isActive !== "undefined") {
        searchParams.push({
          key: "isActive",
          value: params.isActive ? "1" : "0",
        });
      }
    })(),
    isInactive: (() => {
      if (typeof params.isInactive !== "undefined") {
        searchParams.push({
          key: "isInactive",
          value: params.isInactive ? "1" : "0",
        });
      }
    })(),
    startDateLte: (() => {
      if (typeof params.startDateLte !== "undefined") {
        searchParams.push({
          key: "startDateLte",
          value: dayjs()
            .fromTsdDateFormat(params.startDateLte)
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    endDateGte: (() => {
      if (typeof params.endDateGte !== "undefined") {
        searchParams.push({
          key: "endDateGte",
          value: dayjs()
            .fromTsdDateFormat(params.endDateGte)
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    orderByStartDate: (() => {
      if (typeof params.orderByStartDate !== "undefined") {
        searchParams.push({
          key: "orderByStartDate",
          value: params.orderByStartDate,
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<DiscountQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default discountApi;
