import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerPersonalFavourite,
  CustomerPersonalFavouriteCreateParams,
  CustomerPersonalFavouriteQueryParams,
  CustomerPersonalFavouriteUpdateParams,
} from "../../../entities/CustomerPersonalFavourite/customerPersonalFavourite";
import { TsdSearchParam } from "../fetch";

const customerPersonalFavouriteApi = createTsdApi<
  CustomerPersonalFavourite,
  CustomerPersonalFavouriteQueryParams,
  CustomerPersonalFavouriteCreateParams,
  CustomerPersonalFavouriteUpdateParams
>("customer-personal-favourites", (params) => {
  const search: TsdSearchParam<CustomerPersonalFavouriteQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    idGte: (() => {
      if (typeof params.idGte !== "undefined") {
        search.push({ key: "idGte", value: String(params.idGte) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CustomerPersonalFavouriteQueryParams>]:
      | void
      | undefined;
  };

  return search;
});

export default customerPersonalFavouriteApi;
