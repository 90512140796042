import createTsdApi from "../../createTsdApi";
import {
  Event,
  EventCreateParams,
  EventQueryParams,
  EventUpdateParams,
} from "../../../entities/Event/event";
import { TsdSearchParam } from "../fetch";

const eventApi = createTsdApi<
  Event,
  EventQueryParams,
  EventCreateParams,
  EventUpdateParams
>("event", (searchParamsObject) => {
  let searchParams: TsdSearchParam<EventQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    timestampDateGte: (() => {
      if (typeof searchParamsObject.timestampDateGte !== "undefined") {
        searchParams.push({
          key: "timestampDateGte",
          value: searchParamsObject.timestampDateGte,
        });
      }
    })(),
    timestampDateLte: (() => {
      if (typeof searchParamsObject.timestampDateLte !== "undefined") {
        searchParams.push({
          key: "timestampDateLte",
          value: searchParamsObject.timestampDateLte,
        });
      }
    })(),
  }) satisfies { [key in keyof Required<EventQueryParams>]: void | undefined };

  return searchParams;
});

export default eventApi;
