import createTsdApi from "../../../client-api/createTsdApi";
import {
  Log,
  LogQueryParams,
  LogCreateParams,
  LogUpdateParams,
} from "../../../entities/Log/log";
import { TsdSearchParam } from "../fetch";

const logApi = createTsdApi<
  Log,
  LogQueryParams,
  LogCreateParams,
  LogUpdateParams
>("logs", (searchParamsObject) => {
  let searchParams: TsdSearchParam<LogQueryParams>[] = [];

  ({
    action: (() => {
      if (typeof searchParamsObject.action !== "undefined") {
        searchParams.push({
          key: "action",
          value: searchParamsObject.action,
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
  }) satisfies { [key in keyof Required<LogQueryParams>]: void | undefined };

  return searchParams;
});

export default logApi;
