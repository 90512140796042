import tsdFetch from "@tsdir/common/client-api/tsd-booker/fetch";
import handleFetchErrors from "./handleFetchErrors";
import dayjs from "dayjs";
import createAndDownloadCsv from "./createAndDownloadCsv";

type OutOfStockProduct = {
  "Product Name": string;
  Code: string;
  Supplier: string;
  "Date back in stock": string;
};

const isOutOfStockProduct = (val: unknown): val is OutOfStockProduct =>
  typeof val !== "undefined" &&
  val !== null &&
  typeof val === "object" &&
  "Product Name" in val &&
  typeof val["Product Name"] === "string" &&
  "Code" in val &&
  typeof val["Code"] === "string" &&
  "Supplier" in val &&
  typeof val["Supplier"] === "string" &&
  "Date back in stock" in val &&
  typeof val["Date back in stock"] === "string";

const isOutOfStockProductArray = (val: unknown): val is OutOfStockProduct[] =>
  typeof val !== "undefined" &&
  val !== null &&
  Array.isArray(val) &&
  val.every((product) => isOutOfStockProduct(product));

const exportOutOfStockCsv = async () => {
  const url = `${
    import.meta.env.VITE_TSD_LEGACY_PURCHASING_SERVER_BASE
  }ajax/v1/get-out-of-stock-products.php`;

  const response = await tsdFetch(url, {
    method: "GET",
    credentials: "include",
  })
    .then(handleFetchErrors)
    .then((res) => res.json());

  if (!isOutOfStockProductArray(response)) {
    throw new Error("Response is invalid");
  }

  createAndDownloadCsv(
    response,
    {
      "Date back in stock": "Date Back In Stock",
      "Product Name": "Product Name",
      Code: "Code",
      Supplier: "Supplier",
    },
    {
      filename: `Out of stock products - ${dayjs().format("DD/MM/YYYY")}`,
      timestamp: false,
    }
  );
};

export default exportOutOfStockCsv;
