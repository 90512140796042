import createTsdApi from "../../../client-api/createTsdApi";
import {
  SupplierContact,
  SupplierContactCreateParams,
  SupplierContactQueryParams,
  SupplierContactUpdateParams,
} from "../../../entities/SupplierContact/supplierContact";
import { TsdSearchParam } from "../fetch";

const supplierContactApi = createTsdApi<
  SupplierContact,
  SupplierContactQueryParams,
  SupplierContactCreateParams,
  SupplierContactUpdateParams
>("supplier-contacts", (params) => {
  const searchParams: TsdSearchParam<SupplierContactQueryParams>[] = [];

  ({
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) => {
          searchParams.push({
            key: "ids",
            value: String(id),
          });
        });
      }
    })(),
    isActive: (() => {
      if (typeof params.isActive !== "undefined") {
        searchParams.push({
          key: "isActive",
          value: params.isActive ? "1" : "0",
        });
      }
    })(),
    isInactive: (() => {
      if (typeof params.isInactive !== "undefined") {
        searchParams.push({
          key: "isInactive",
          value: params.isInactive ? "1" : "0",
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: params.isDeleted ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    searchTerm: (() => {
      if (typeof params.searchTerm !== "undefined") {
        searchParams.push({
          key: "searchTerm",
          value: params.searchTerm,
        });
      }
    })(),
    orgIds: (() => {
      if (typeof params.orgIds !== "undefined") {
        params.orgIds.forEach((id) => {
          searchParams.push({
            key: "orgIds",
            value: String(id),
          });
        });
      }
    })(),
    telephoneSearchTerm: (() => {
      if (typeof params.telephoneSearchTerm !== "undefined") {
        searchParams.push({
          key: "telephoneSearchTerm",
          value: params.telephoneSearchTerm,
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<SupplierContactQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default supplierContactApi;
