import createTsdApi from "../../../client-api/createTsdApi";
import {
  SupplierBackOrderInformation,
  SupplierBackOrderInformationCreateParams,
  SupplierBackOrderInformationQueryParams,
  SupplierBackOrderInformationUpdateParams,
} from "../../../entities/SupplierBackOrderInformation/supplierBackOrderInformation";
import { TsdSearchParam } from "../fetch";

const supplierBackOrderInformationApi = createTsdApi<
  SupplierBackOrderInformation,
  SupplierBackOrderInformationQueryParams,
  SupplierBackOrderInformationCreateParams,
  SupplierBackOrderInformationUpdateParams
>("supplier-back-order-information", (params) => {
  const search: TsdSearchParam<SupplierBackOrderInformationQueryParams>[] = [];

  ({
    jobCode: (() => {
      if (typeof params.jobCode !== "undefined") {
        search.push({ key: "jobCode", value: params.jobCode });
      }
    })(),
  }) satisfies {
    [key in keyof Required<SupplierBackOrderInformationQueryParams>]:
      | void
      | undefined;
  };

  return search;
});

export default supplierBackOrderInformationApi;
