import {
  OrderItem,
  OrderItemCreateParams,
  OrderItemQueryParams,
  OrderItemUpdateParams,
} from "../../../entities/OrderItem/orderItem";
import createTsdApi from "../../../client-api/createTsdApi";
import { TsdSearchParam } from "../fetch";

const orderItemApi = createTsdApi<
  OrderItem,
  OrderItemQueryParams,
  OrderItemCreateParams,
  OrderItemUpdateParams
>("order-items", (searchParamsObject) => {
  let searchParams: TsdSearchParam<OrderItemQueryParams>[] = [];

  if (typeof searchParamsObject.ids !== "undefined") {
    searchParamsObject.ids.forEach((id) => {
      searchParams.push({
        key: "ids",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.supplierIds !== "undefined") {
    searchParamsObject.supplierIds.forEach((id) => {
      searchParams.push({
        key: "supplierIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.orderIds !== "undefined") {
    searchParamsObject.orderIds.forEach((id) => {
      searchParams.push({
        key: "orderIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.customerIds !== "undefined") {
    searchParamsObject.customerIds.forEach((id) => {
      searchParams.push({
        key: "customerIds",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.jobDeliveryDateGte !== "undefined") {
    searchParams.push({
      key: "jobDeliveryDateGte",
      value: searchParamsObject.jobDeliveryDateGte,
    });
  }

  if (typeof searchParamsObject.jobDeliveryDateLte !== "undefined") {
    searchParams.push({
      key: "jobDeliveryDateLte",
      value: searchParamsObject.jobDeliveryDateLte,
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  if (typeof searchParamsObject.ids !== "undefined") {
    searchParamsObject.ids.forEach((id) => {
      searchParams.push({
        key: "ids",
        value: String(id),
      });
    });
  }

  if (typeof searchParamsObject.statuses !== "undefined") {
    searchParamsObject.statuses.forEach((status) => {
      searchParams.push({
        key: "statuses",
        value: status,
      });
    });
  }

  if (typeof searchParamsObject.quantityGte !== "undefined") {
    searchParams.push({
      key: "quantityGte",
      value: String(searchParamsObject.quantityGte),
    });
  }

  if (typeof searchParamsObject.createdGte !== "undefined") {
    searchParams.push({
      key: "createdGte",
      value: searchParamsObject.createdGte,
    });
  }

  if (typeof searchParamsObject.createdLte !== "undefined") {
    searchParams.push({
      key: "createdLte",
      value: searchParamsObject.createdLte,
    });
  }

  if (typeof searchParamsObject.idGte !== "undefined") {
    searchParams.push({
      key: "idGte",
      value: String(searchParamsObject.idGte),
    });
  }

  if (typeof searchParamsObject.idLte !== "undefined") {
    searchParams.push({
      key: "idLte",
      value: String(searchParamsObject.idLte),
    });
  }

  if (typeof searchParamsObject.idGt !== "undefined") {
    searchParams.push({
      key: "idGt",
      value: String(searchParamsObject.idGt),
    });
  }

  if (typeof searchParamsObject.idLt !== "undefined") {
    searchParams.push({
      key: "idLt",
      value: String(searchParamsObject.idLt),
    });
  }

  if (typeof searchParamsObject.orderById !== "undefined") {
    searchParams.push({
      key: "orderById",
      value: searchParamsObject.orderById,
    });
  }

  return searchParams;
});

export default orderItemApi;
