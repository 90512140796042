import dayjs, { PluginFunc } from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
dayjs.extend(utc);
// Workaround for
// TypeError: (0 , _dayjs.default)(...).fromTsdDateFormat(...).tz is not a function
dayjs.extend(tz);
import { TsdDate } from "../../types";

declare module "dayjs" {
  interface Dayjs {
    fromTsdDateFormat(tsdDate: TsdDate): dayjs.Dayjs;
  }
}

const fromTsdDateFormat: PluginFunc = (option, DayjsClass, djs) => {
  // Extend the prototype of Day.js
  DayjsClass.prototype.fromTsdDateFormat = function (customObj: TsdDate) {
    // Extract the day, month, and year components from the custom object
    const { d, m, y } = customObj;

    // Create a new Day.js object with the provided components
    return djs()
      .set("month", m - 1)
      .set("year", y)
      .set("date", d)
      .utc(true)
      .startOf("day");
  };
};

export default fromTsdDateFormat;
