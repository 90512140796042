import createTsdApi from "../../../client-api/createTsdApi";
import {
  AutoItemCheckerLog,
  AutoItemCheckerLogCreateParams,
  AutoItemCheckerLogQueryParams,
  AutoItemCheckerLogUpdateParams,
} from "../../../entities/AutoItemCheckerLog/autoItemCheckerLog";
import { TsdSearchParam } from "../fetch";

const autoItemCheckerLogApi = createTsdApi<
  AutoItemCheckerLog,
  AutoItemCheckerLogQueryParams,
  AutoItemCheckerLogCreateParams,
  AutoItemCheckerLogUpdateParams
>("auto-item-checker-logs", (searchParamsObject) => {
  const searchParams: TsdSearchParam<AutoItemCheckerLogQueryParams>[] = [];

  ({
    orderIds: (() => {
      if (typeof searchParamsObject.orderIds !== "undefined") {
        searchParamsObject.orderIds.forEach((id) => {
          searchParams.push({ key: "orderIds", value: String(id) });
        });
      }
    })(),

    isSupplierDocumentNumberNull: (() => {
      if (
        typeof searchParamsObject.isSupplierDocumentNumberNull !== "undefined"
      ) {
        searchParams.push({
          key: "isSupplierDocumentNumberNull",
          value: searchParamsObject.isSupplierDocumentNumberNull ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<AutoItemCheckerLogQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default autoItemCheckerLogApi;
