import createTsdApi from "../../../client-api/createTsdApi";
import {
  VOWJobError,
  VOWJobErrorCreateParams,
  VOWJobErrorQueryParams,
  VOWJobErrorUpdateParams,
} from "../../../entities/vowJobError";
import { TsdSearchParam } from "../fetch";

const VOWJobErrorApi = createTsdApi<
  VOWJobError,
  VOWJobErrorQueryParams,
  VOWJobErrorCreateParams,
  VOWJobErrorUpdateParams
>("vow-job-errors", (searchParamsObject) => {
  let searchParams: TsdSearchParam<VOWJobErrorQueryParams>[] = [];

  if (typeof searchParamsObject.jobCode !== "undefined") {
    searchParams.push({
      key: "jobCode",
      value: String(searchParamsObject.jobCode),
    });
  }

  if (typeof searchParamsObject.jobId !== "undefined") {
    searchParams.push({
      key: "jobId",
      value: String(searchParamsObject.jobId),
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  return searchParams;
});

export default VOWJobErrorApi;
