import createTsdApi from "../../../client-api/createTsdApi";
import {
  SupplierOrgContact,
  SupplierOrgContactQueryParams,
  SupplierOrgContactCreateParams,
  SupplierOrgContactUpdateParams,
} from "../../../entities/SupplierOrgContact/supplierOrgContact";
import { TsdSearchParam } from "../fetch";

const supplierOrgContactApi = createTsdApi<
  SupplierOrgContact,
  SupplierOrgContactQueryParams,
  SupplierOrgContactCreateParams,
  SupplierOrgContactUpdateParams
>("supplier-org-contacts", (params) => {
  const searchParams: TsdSearchParam<SupplierOrgContactQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    orgIds: (() => {
      if (typeof params.orgIds !== "undefined") {
        params.orgIds.forEach((id) => {
          searchParams.push({
            key: "orgIds",
            value: String(id),
          });
        });
      }
    })(),
    contactIds: (() => {
      if (typeof params.contactIds !== "undefined") {
        params.contactIds.forEach((id) => {
          searchParams.push({
            key: "contactIds",
            value: String(id),
          });
        });
      }
    })(),
    isReceivesDailySummary: (() => {
      if (typeof params.isReceivesDailySummary !== "undefined") {
        searchParams.push({
          key: "isReceivesDailySummary",
          value: params.isReceivesDailySummary ? "1" : "0",
        });
      }
    })(),
    isReceivesNotifications: (() => {
      if (typeof params.isReceivesNotifications !== "undefined") {
        searchParams.push({
          key: "isReceivesNotifications",
          value: params.isReceivesNotifications ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<SupplierOrgContactQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default supplierOrgContactApi;
