import dayjs from "dayjs";
import createTsdApi from "../../../client-api/createTsdApi";
import {
  BudgetAmountAdjustment,
  BudgetAmountAdjustmentCreateParams,
  BudgetAmountAdjustmentQueryParams,
  BudgetAmountAdjustmentUpdateParams,
} from "../../../entities/BudgetAmountAdjustment/budgetAmountAdjustment";
import { TsdSearchParam } from "../fetch";

const budgetAmountAdjustmentApi = createTsdApi<
  BudgetAmountAdjustment,
  BudgetAmountAdjustmentQueryParams,
  BudgetAmountAdjustmentCreateParams,
  BudgetAmountAdjustmentUpdateParams
>("budget-amount-adjustments", (params) => {
  const search: TsdSearchParam<BudgetAmountAdjustmentQueryParams>[] = [];

  ({
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) =>
          search.push({ key: "ids", value: String(id) })
        );
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        search.push({ key: "isDeleted", value: params.isDeleted ? "1" : "0" });
      }
    })(),
    budgetIds: (() => {
      if (typeof params.budgetIds !== "undefined") {
        params.budgetIds.forEach((id) =>
          search.push({ key: "budgetIds", value: String(id) })
        );
      }
    })(),
    dateGte: (() => {
      if (typeof params.dateGte !== "undefined") {
        search.push({
          key: "dateGte",
          value: dayjs()
            .fromTsdDateFormat(params.dateGte)
            .utc()
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    dateLte: (() => {
      if (typeof params.dateLte !== "undefined") {
        search.push({
          key: "dateLte",
          value: dayjs()
            .fromTsdDateFormat(params.dateLte)
            .utc()
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    jobIds: (() => {
      if (typeof params.jobIds !== "undefined") {
        params.jobIds.forEach((id) =>
          search.push({ key: "jobIds", value: String(id) })
        );
      }
    })(),
  }) satisfies {
    [key in keyof Required<BudgetAmountAdjustmentQueryParams>]:
      | void
      | undefined;
  };

  return search;
});

export default {
  get: budgetAmountAdjustmentApi.get,
  getOne: budgetAmountAdjustmentApi.getOne,
  create: budgetAmountAdjustmentApi.create,
  update: budgetAmountAdjustmentApi.update,
};
