import createTsdApi from "../../../client-api/createTsdApi";
import {
  DeliveryCharge,
  DeliveryChargeCreateParams,
  DeliveryChargeQueryParams,
  DeliveryChargeUpdateParams,
} from "../../../entities/DeliveryCharge/deliveryCharge";
import { TsdSearchParam } from "../fetch";

const deliveryChargeApi = createTsdApi<
  DeliveryCharge,
  DeliveryChargeCreateParams,
  DeliveryChargeQueryParams,
  DeliveryChargeUpdateParams
>("delivery-charges", (_params) => {
  const search: TsdSearchParam<DeliveryChargeQueryParams>[] = [];

  return search;
});

export default deliveryChargeApi;
