import createTsdApi from "../../../client-api/createTsdApi";
import {
  MaterialisedProductAnalysis,
  MaterialisedProductAnalysisCreateParams,
  MaterialisedProductAnalysisQueryParams,
  MaterialisedProductAnalysisUpdateParams,
} from "../../../entities/MaterialisedProductAnalysis/materialisedProductAnalysis";
import tsdFetch, { TsdSearchParam } from "../fetch";

const materialisedProductAnalysisApi = createTsdApi<
  MaterialisedProductAnalysis,
  MaterialisedProductAnalysisQueryParams,
  MaterialisedProductAnalysisCreateParams,
  MaterialisedProductAnalysisUpdateParams
>("materialised-product-analysis", (params) => {
  const search: TsdSearchParam<MaterialisedProductAnalysisQueryParams>[] = [];

  ({
    orderItemIds: (() => {
      if (typeof params.orderItemIds !== "undefined") {
        params.orderItemIds.forEach((id) =>
          search.push({ key: "orderItemIds", value: String(id) })
        );
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<MaterialisedProductAnalysisQueryParams>]:
      | void
      | undefined;
  };

  return search;
});

const createMany = async (
  newRecords: MaterialisedProductAnalysisCreateParams[]
) => {
  return tsdFetch(`api/v1/bulk/create-materialised-product-analysis`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newRecords),
  });
};

export default {
  get: materialisedProductAnalysisApi.get,
  getOne: materialisedProductAnalysisApi.getOne,
  create: materialisedProductAnalysisApi.create,
  update: materialisedProductAnalysisApi.update,
  createMany,
};
