import createTsdApi from "../../../client-api/createTsdApi";
import {
  Sector,
  SectorCreateParams,
  SectorQueryParams,
  SectorUpdateParams,
} from "../../../entities/sector";
import { TsdSearchParam } from "../fetch";

const sectorApi = createTsdApi<
  Sector,
  SectorQueryParams,
  SectorCreateParams,
  SectorUpdateParams
>("sectors", (params) => {
  const searchParams: TsdSearchParam<SectorQueryParams>[] = [];
  ({
    name: (() => {
      if (typeof params.name !== "undefined") {
        searchParams.push({ key: "name", value: params.name });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({ key: "offset", value: String(params.offset) });
      }
    })(),
  }) satisfies { [key in keyof Required<SectorQueryParams>]: void | undefined };

  return searchParams;
});

export default sectorApi;
