import Cookie from "js-cookie";

type SearchParam = {
  key: string;
  value?: string;
};

interface TsdResponse extends Response {
  json: () => Promise<any>;
}

const tsdFetch = async (
  endpoint: string,
  init: RequestInit,
  searchParams?: SearchParam[]
) => {
  const url = new URL(endpoint);

  if (searchParams) {
    searchParams.forEach((searchParam) => {
      if (typeof searchParam.value !== "undefined") {
        url.searchParams.append(searchParam.key, searchParam.value);
      }
    });
  }

  const bearer = `Bearer ${Cookie.get("jwt")}`;

  return fetch(url.href, {
    ...init,
    credentials: "include",
    headers: { ...init.headers, Authorization: bearer },
  }) as Promise<TsdResponse>;
};

export default tsdFetch;
