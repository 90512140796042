import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerNoteTag,
  CustomerNoteTagQueryParams,
  CustomerNoteTagCreateParams,
  CustomerNoteTagUpdateParams,
} from "../../../entities/CustomerNoteTag/customerNoteTag";
import { TsdSearchParam } from "../fetch";

const customerNoteTagApi = createTsdApi<
  CustomerNoteTag,
  CustomerNoteTagQueryParams,
  CustomerNoteTagCreateParams,
  CustomerNoteTagUpdateParams
>("customer-note-tags", (params) => {
  const searchParams: TsdSearchParam<CustomerNoteTagQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    tags: (() => {
      if (typeof params.tags !== "undefined") {
        params.tags.forEach((tag) => {
          searchParams.push({ key: "tags", value: tag });
        });
      }
    })(),
    customerNoteIds: (() => {
      if (typeof params.customerNoteIds !== "undefined") {
        params.customerNoteIds.forEach((id) => {
          searchParams.push({ key: "customerNoteIds", value: String(id) });
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: params.isDeleted ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CustomerNoteTagQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default customerNoteTagApi;
