import createTsdApi from "../../../client-api/createTsdApi";
import {
  PageAccess,
  PageAccessQueryParams,
} from "../../../entities/pageAccess";
import { TsdSearchParam } from "../fetch";

const pageAccessApi = createTsdApi<PageAccess, PageAccessQueryParams, {}, {}>(
  "page-access",
  (searchParamsObject) => {
    let searchParams: TsdSearchParam<PageAccessQueryParams>[] = [];

    if (typeof searchParamsObject.userId !== "undefined") {
      searchParams.push({
        key: "userId",
        value: String(searchParamsObject.userId),
      });
    }

    if (typeof searchParamsObject.pageId !== "undefined") {
      searchParams.push({
        key: "pageId",
        value: String(searchParamsObject.pageId),
      });
    }

    if (typeof searchParamsObject.limit !== "undefined") {
      searchParams.push({
        key: "limit",
        value: String(searchParamsObject.limit),
      });
    }

    if (typeof searchParamsObject.offset !== "undefined") {
      searchParams.push({
        key: "offset",
        value: String(searchParamsObject.offset),
      });
    }

    return searchParams;
  }
);

export default pageAccessApi;
