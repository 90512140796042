import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerToSupplierAccount,
  CustomerToSupplierAccountCreateParams,
  CustomerToSupplierAccountQueryParams,
  CustomerToSupplierAccountUpdateParams,
} from "../../../entities/customerToSupplierAccount";
import { TsdSearchParam } from "../fetch";

const customerToSupplierAccountApi = createTsdApi<
  CustomerToSupplierAccount,
  CustomerToSupplierAccountQueryParams,
  CustomerToSupplierAccountCreateParams,
  CustomerToSupplierAccountUpdateParams
>("customer-to-supplier-accounts", (params) => {
  const search: TsdSearchParam<CustomerToSupplierAccountQueryParams>[] = [];

  ({
    allowAccess: (() => {
      if (typeof params.allowAccess !== "undefined") {
        search.push({
          key: "allowAccess",
          value: params.allowAccess ? "1" : "0",
        });
      }
    })(),
    customerToSupplierIds: (() => {
      if (typeof params.customerToSupplierIds !== "undefined") {
        params.customerToSupplierIds.forEach((id) => {
          search.push({
            key: "customerToSupplierIds",
            value: String(id),
          });
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        search.push({
          key: "isDeleted",
          value: params.isDeleted ? "1" : "0",
        });
      }
    })(),
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) => {
          search.push({
            key: "ids",
            value: String(id),
          });
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CustomerToSupplierAccountQueryParams>]:
      | void
      | undefined;
  };

  return search;
});

export default customerToSupplierAccountApi;
