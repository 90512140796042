import createTsdApi from "../../createTsdApi";
import {
  ProductSupplierAccount,
  ProductSupplierAccountCreateParams,
  ProductSupplierAccountQueryParams,
  ProductSupplierAccountUpdateParams,
} from "../../../entities/productSupplierAccount";
import { TsdSearchParam } from "../fetch";

const productSupplierAccountApi = createTsdApi<
  ProductSupplierAccount,
  ProductSupplierAccountQueryParams,
  ProductSupplierAccountCreateParams,
  ProductSupplierAccountUpdateParams
>("product-supplier-accounts", (searchParamsObject) => {
  let searchParams: TsdSearchParam<ProductSupplierAccountQueryParams>[] = [];

  ({
    supplierAccountId: (() => {
      if (typeof searchParamsObject.supplierAccountId !== "undefined") {
        searchParams.push({
          key: "supplierAccountId",
          value: String(searchParamsObject.supplierAccountId),
        });
      }
    })(),
    productId: (() => {
      if (typeof searchParamsObject.productId !== "undefined") {
        searchParams.push({
          key: "productId",
          value: String(searchParamsObject.productId),
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ProductSupplierAccountQueryParams>]:
      | void
      | undefined;
  };

  return searchParams;
});

export default productSupplierAccountApi;
