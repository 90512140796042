import createTsdApi from "../../../client-api/createTsdApi";
import {
  JobDocument,
  JobDocumentCreateParams,
  JobDocumentQueryParams,
  JobDocumentUpdateParams,
} from "../../../entities/JobDocument/jobDocument";
import { TsdSearchParam } from "../fetch";

const jobDocumentApi = createTsdApi<
  JobDocument,
  JobDocumentQueryParams,
  JobDocumentCreateParams,
  JobDocumentUpdateParams
>("job-documents", (paramsObject) => {
  const queryParams: TsdSearchParam<JobDocumentQueryParams>[] = [];

  ({
    jobIds: (() => {
      if (typeof paramsObject.jobIds !== "undefined") {
        paramsObject.jobIds.forEach((id) => {
          queryParams.push({
            key: "jobIds",
            value: String(id),
          });
        });
      }
    })(),
    uploadIds: (() => {
      if (typeof paramsObject.uploadIds !== "undefined") {
        paramsObject.uploadIds.forEach((id) => {
          queryParams.push({
            key: "uploadIds",
            value: String(id),
          });
        });
      }
    })(),
    uploaderTypes: (() => {
      if (typeof paramsObject.uploaderTypes !== "undefined") {
        paramsObject.uploaderTypes.forEach((type) => {
          queryParams.push({
            key: "uploaderTypes",
            value: type,
          });
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof paramsObject.isDeleted !== "undefined") {
        queryParams.push({
          key: "isDeleted",
          value: paramsObject.isDeleted ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<JobDocumentQueryParams>]: void | undefined;
  };

  return queryParams;
});

export default jobDocumentApi;
