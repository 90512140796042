import createTsdApi from "../../../client-api/createTsdApi";
import {
  ProductStock,
  ProductStockCreateParams,
  ProductStockQueryParams,
  ProductStockUpdateParams,
} from "../../../entities/ProductStock/productStock";
import { TsdSearchParam } from "../fetch";

const productStockApi = createTsdApi<
  ProductStock,
  ProductStockQueryParams,
  ProductStockCreateParams,
  ProductStockUpdateParams
>("product-stocks", (params) => {
  const search: TsdSearchParam<ProductStockQueryParams>[] = [];

  ({
    productOriginalIds: (() => {
      if (typeof params.productOriginalIds !== "undefined") {
        params.productOriginalIds.forEach((id) => {
          search.push({
            key: "productOriginalIds",
            value: String(id),
          });
        });
      }
    })(),
    sortByCreated: (() => {
      if (typeof params.sortByCreated !== "undefined") {
        search.push({
          key: "sortByCreated",
          value: params.sortByCreated,
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    mostRecentRowForProductOriginalIds: (() => {
      if (typeof params.mostRecentRowForProductOriginalIds !== "undefined") {
        params.mostRecentRowForProductOriginalIds.forEach((id) =>
          search.push({
            key: "mostRecentRowForProductOriginalIds",
            value: String(id),
          })
        );
      }
    })(),
  }) satisfies {
    [key in keyof Required<ProductStockQueryParams>]: void | undefined;
  };

  return search;
});

export default productStockApi;
