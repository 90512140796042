import createTsdApi from "../../../client-api/createTsdApi";
import {
  Invoice,
  InvoiceCreateParams,
  InvoiceQueryParams,
  InvoiceUpdateParams,
} from "../../../entities/Invoice/invoice";
import { TsdSearchParam } from "../fetch";

const invoiceApi = createTsdApi<
  Invoice,
  InvoiceQueryParams,
  InvoiceCreateParams,
  InvoiceUpdateParams
>("invoices", (params) => {
  const search: TsdSearchParam<InvoiceQueryParams>[] = [];

  ({
    invoiceDateGte: (() => {
      if (typeof params.invoiceDateGte !== "undefined") {
        search.push({
          key: "invoiceDateGte",
          value: params.invoiceDateGte,
        });
      }
    })(),
    invoiceDateLte: (() => {
      if (typeof params.invoiceDateLte !== "undefined") {
        search.push({
          key: "invoiceDateLte",
          value: params.invoiceDateLte,
        });
      }
    })(),
    isPaid: (() => {
      if (typeof params.isPaid !== "undefined") {
        search.push({
          key: "isPaid",
          value: params.isPaid ? "1" : "0",
        });
      }
    })(),
    stages: (() => {
      if (typeof params.stages !== "undefined") {
        params.stages.forEach((stage) => {
          search.push({ key: "stages", value: stage });
        });
      }
    })(),
    supplierIds: (() => {
      if (typeof params.supplierIds !== "undefined") {
        params.supplierIds.forEach((id) => {
          search.push({
            key: "supplierIds",
            value: String(id),
          });
        });
      }
    })(),
    isCustomerInvoice: (() => {
      if (typeof params.isCustomerInvoice !== "undefined") {
        search.push({
          key: "isCustomerInvoice",
          value: params.isCustomerInvoice ? "1" : "0",
        });
      }
    })(),
    isSupplierInvoice: (() => {
      if (typeof params.isSupplierInvoice !== "undefined") {
        search.push({
          key: "isSupplierInvoice",
          value: params.isSupplierInvoice ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
    statuses: (() => {
      if (typeof params.statuses !== "undefined") {
        params.statuses.forEach((status) => {
          search.push({ key: "statuses", value: status });
        });
      }
    })(),
    customerIds: (() => {
      if (typeof params.customerIds !== "undefined") {
        params.customerIds.forEach((id) => {
          search.push({
            key: "customerIds",
            value: String(id),
          });
        });
      }
    })(),
    orderByInvoiceRef: (() => {
      if (typeof params.orderByInvoiceRef !== "undefined") {
        search.push({
          key: "orderByInvoiceRef",
          value: params.orderByInvoiceRef,
        });
      }
    })(),
    invoiceRefs: (() => {
      if (typeof params.invoiceRefs !== "undefined") {
        params.invoiceRefs.forEach((invoiceRef) => {
          search.push({
            key: "invoiceRefs",
            value: String(invoiceRef),
          });
        });
      }
    })(),
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) => {
          search.push({
            key: "ids",
            value: String(id),
          });
        });
      }
    })(),
    orderByInvoiceDate: (() => {
      if (typeof params.orderByInvoiceDate !== "undefined") {
        search.push({
          key: "orderByInvoiceDate",
          value: params.orderByInvoiceDate,
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<InvoiceQueryParams>]: void | undefined;
  };

  return search;
});

export default invoiceApi;
