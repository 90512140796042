import createTsdApi from "../../../client-api/createTsdApi";
import {
  Reminder,
  ReminderCreateParams,
  ReminderQueryParams,
  ReminderUpdateParams,
} from "../../../entities/reminder";
import { TsdSearchParam } from "../fetch";

const reminderApi = createTsdApi<
  Reminder,
  ReminderQueryParams,
  ReminderCreateParams,
  ReminderUpdateParams
>("reminders", (searchParamsObject) => {
  let searchParams: TsdSearchParam<ReminderQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    searchTerm: (() => {
      if (typeof searchParamsObject.searchTerm !== "undefined") {
        searchParams.push({
          key: "searchTerm",
          value: searchParamsObject.searchTerm,
        });
      }
    })(),
    startDateLte: (() => {
      if (typeof searchParamsObject.startDateLte !== "undefined") {
        searchParams.push({
          key: "startDateLte",
          value: searchParamsObject.startDateLte,
        });
      }
    })(),
    endDate: (() => {
      if (typeof searchParamsObject.endDate !== "undefined") {
        searchParams.push({
          key: "endDate",
          value: searchParamsObject.endDate,
        });
      }
    })(),
    dayId: (() => {
      if (typeof searchParamsObject.dayId !== "undefined") {
        searchParams.push({
          key: "dayId",
          value: String(searchParamsObject.dayId),
        });
      }
    })(),
    isActive: (() => {
      if (typeof searchParamsObject.isActive !== "undefined") {
        searchParams.push({
          key: "isActive",
          value: searchParamsObject.isActive ? "1" : "0",
        });
      }
    })(),
    onHold: (() => {
      if (typeof searchParamsObject.onHold !== "undefined") {
        searchParams.push({
          key: "onHold",
          value: searchParamsObject.onHold ? "1" : "0",
        });
      }
    })(),
    hasBeenHandledToday: (() => {
      if (typeof searchParamsObject.hasBeenHandledToday !== "undefined") {
        searchParams.push({
          key: "hasBeenHandledToday",
          value: searchParamsObject.hasBeenHandledToday ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ReminderQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default reminderApi;
