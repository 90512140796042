import { ITsdFetch } from "./tsd-api";
import tsdFetch, { IMapFunction } from "./tsd-api/fetch";

type AdditionalFunctions = {
  [key: string]: Function;
};

export type TsdApi<Entity, GetParams, CreateParams, UpdateParams> = {
  create: (newEntity: CreateParams) => ITsdFetch<Entity>;
  get: (params: GetParams) => ITsdFetch<Entity[]>;
  getOne: (id: number) => ITsdFetch<Entity>;
  update: (id: number, fields: UpdateParams) => ITsdFetch<Entity>;
  delete: (id: number) => ITsdFetch<void>;
  [key: string]: Function;
};

const createTsdApi = <Entity, GetParams, CreateParams, UpdateParams>(
  apiUrl: string,
  mapParamsToQueryString: IMapFunction<GetParams>,
  additionalFunctions?: AdditionalFunctions
): TsdApi<Entity, GetParams, CreateParams, UpdateParams> => {
  let url = `/api/v1/${apiUrl}`;

  return {
    create: (newEntity: CreateParams) => {
      return tsdFetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newEntity),
      });
    },
    get: (params: GetParams) => {
      return tsdFetch(url, { method: "GET" }, mapParamsToQueryString(params));
    },
    getOne: (id: number) => {
      return tsdFetch(`${url}/${id}`, { method: "GET" });
    },
    update: (id: number, fields: UpdateParams) => {
      return tsdFetch(`${url}/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(fields),
      });
    },
    delete: (id: number) => {
      return tsdFetch(`${url}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    ...additionalFunctions,
  };
};

export default createTsdApi;
