import createTsdApi from "../../../client-api/createTsdApi";
import {
  ChangeLog,
  ChangeLogCreateParams,
  ChangeLogQueryParams,
  ChangeLogUpdateParams,
} from "../../../entities/changeLog";
import { TsdSearchParam } from "../fetch";

const changeLogApi = createTsdApi<
  ChangeLog,
  ChangeLogQueryParams,
  ChangeLogCreateParams,
  ChangeLogUpdateParams
>("change-logs", (params) => {
  const search: TsdSearchParam<ChangeLogQueryParams>[] = [];

  ({
    createdAtGte: (() => {
      if (typeof params.createdAtGte !== "undefined") {
        search.push({ key: "createdAtGte", value: params.createdAtGte });
      }
    })(),
    recordId: (() => {
      if (typeof params.recordId !== "undefined") {
        search.push({ key: "recordId", value: String(params.recordId) });
      }
    })(),
    userId: (() => {
      if (typeof params.userId !== "undefined") {
        search.push({ key: "userId", value: String(params.userId) });
      }
    })(),
    table: (() => {
      if (typeof params.table !== "undefined") {
        search.push({ key: "table", value: params.table });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
    orderByCreated: (() => {
      if (typeof params.orderByCreated !== "undefined") {
        search.push({
          key: "orderByCreated",
          value: params.orderByCreated,
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ChangeLogQueryParams>]: void | undefined;
  };

  return search;
});

export default changeLogApi;
