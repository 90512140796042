import createTsdApi from "../../../client-api/createTsdApi";
import {
  PrebuiltOrder,
  PrebuiltOrderQueryParams,
  PrebuiltOrderCreateParams,
  PrebuiltOrderUpdateParams,
} from "../../../entities/prebuiltOrder";
import { TsdSearchParam } from "../fetch";

const prebuiltOrderApi = createTsdApi<
  PrebuiltOrder,
  PrebuiltOrderQueryParams,
  PrebuiltOrderCreateParams,
  PrebuiltOrderUpdateParams
>("prebuilt-orders", (searchParamsObject) => {
  let searchParams: TsdSearchParam<PrebuiltOrderQueryParams>[] = [];

  ({
    isDeleted: (() => {
      if (typeof searchParamsObject.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: searchParamsObject.isDeleted ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<PrebuiltOrderQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default prebuiltOrderApi;
