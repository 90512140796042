import { PluginFunc } from "dayjs";
import { TsdDate } from "../../types";

declare module "dayjs" {
  interface Dayjs {
    toTsdDateFormat(): TsdDate;
  }
}

const toTsdDateFormat: PluginFunc = (_, c) => {
  c.prototype.toTsdDateFormat = function () {
    if (!this.isValid()) {
      return { d: 0, m: 0, y: 0 };
    }

    // Extract the day, month, and year components
    const day = this.date();
    const month = this.month() + 1; // Months are zero-based in Day.js
    const year = this.year();

    // Return the formatted object
    return { d: day, m: month, y: year };
  };
};

export default toTsdDateFormat;
