import createTsdApi from "../../../client-api/createTsdApi";
import {
  CommissionType,
  CommissionTypeCreateParams,
  CommissionTypeQueryParams,
  CommissionTypeUpdateParams,
} from "../../../entities/commissionType";
import { TsdSearchParam } from "../fetch";

const commissionTypeApi = createTsdApi<
  CommissionType,
  CommissionTypeQueryParams,
  CommissionTypeCreateParams,
  CommissionTypeUpdateParams
>("commission-types", (params) => {
  const searchParams: TsdSearchParam<CommissionTypeQueryParams>[] = [];

  ({
    isActive: (() => {
      if (typeof params.isActive !== "undefined") {
        searchParams.push({
          key: "isActive",
          value: params.isActive ? "1" : "0",
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: params.isDeleted ? "1" : "0",
        });
      }
    })(),
    supplierIds: (() => {
      if (typeof params.supplierIds !== "undefined") {
        params.supplierIds.forEach((supplierId) => {
          searchParams.push({
            key: "supplierIds",
            value: String(supplierId),
          });
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CommissionTypeQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default commissionTypeApi;
