import createTsdApi from "../../../client-api/createTsdApi";
import {
  Feature,
  FeatureCreateParams,
  FeatureQueryParams,
  FeatureUpdateParams,
} from "../../../entities/Feature/feature";
import { TsdSearchParam } from "../fetch";

const featureApi = createTsdApi<
  Feature,
  FeatureQueryParams,
  FeatureCreateParams,
  FeatureUpdateParams
>("features", (params) => {
  const search: TsdSearchParam<FeatureQueryParams>[] = [];

  ({
    name: (() => {
      if (typeof params.name !== "undefined") {
        search.push({
          key: "name",
          value: params.name,
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        search.push({
          key: "isDeleted",
          value: params.isDeleted ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<FeatureQueryParams>]: void | undefined;
  };

  return search;
});

export default featureApi;
