import createTsdApi from "../../../client-api/createTsdApi";
import {
  BudgetAmountLog,
  BudgetAmountLogCreateParams,
  BudgetAmountLogQueryParams,
  BudgetAmountLogUpdateParams,
} from "../../../entities/BudgetAmountLog/budgetAmountLog";
import { TsdSearchParam } from "../fetch";

const budgetAmountLogApi = createTsdApi<
  BudgetAmountLog,
  BudgetAmountLogQueryParams,
  BudgetAmountLogCreateParams,
  BudgetAmountLogUpdateParams
>("budget-amount-logs", (params) => {
  const search: TsdSearchParam<BudgetAmountLogQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
    budgetIds: (() => {
      if (typeof params.budgetIds !== "undefined") {
        params.budgetIds.forEach((id) => {
          search.push({ key: "budgetIds", value: String(id) });
        });
      }
    })(),
    contactIds: (() => {
      if (typeof params.contactIds !== "undefined") {
        params.contactIds.forEach((id) => {
          search.push({ key: "contactIds", value: String(id) });
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<BudgetAmountLogQueryParams>]: void | undefined;
  };

  return search;
});

export default budgetAmountLogApi;
