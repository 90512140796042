import createTsdApi from "../../../client-api/createTsdApi";
import {
  Menu,
  MenuQueryParams,
  MenuUpdateParams,
  MenuCreateParams,
} from "../../../entities/Menu/menu";
import { TsdSearchParam } from "../fetch";

const menuApi = createTsdApi<
  Menu,
  MenuQueryParams,
  MenuCreateParams,
  MenuUpdateParams
>("menus", (params) => {
  const searchParams: TsdSearchParam<MenuQueryParams>[] = [];

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  return searchParams;
});

export default menuApi;
