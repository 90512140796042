import {
  ProductAttributeGroup,
  ProductAttributeGroupQueryParams,
  ProductAttributeGroupCreateParams,
  ProductAttributeGroupUpdateParams,
} from "../../../entities/ProductAttributeGroup/productAttributeGroup";
import createTsdApi from "../../../client-api/createTsdApi";
import { TsdSearchParam } from "../fetch";

const productAttributeGroupApi = createTsdApi<
  ProductAttributeGroup,
  ProductAttributeGroupQueryParams,
  ProductAttributeGroupCreateParams,
  ProductAttributeGroupUpdateParams
>("product-attribute-groups", (searchParamsObject) => {
  const searchParams: TsdSearchParam<ProductAttributeGroupQueryParams>[] = [];

  ({
    isActive: (() => {
      if (typeof searchParamsObject.isActive !== "undefined") {
        searchParams.push({
          key: "isActive",
          value: searchParamsObject.isActive ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ProductAttributeGroupQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default productAttributeGroupApi;
