import createTsdApi from "../../../client-api/createTsdApi";
import {
  ProductAttribute,
  ProductAttributeCreateParams,
  ProductAttributeQueryParams,
  ProductAttributeUpdateParams,
} from "../../../entities/ProductAttribute/productAttribute";
import { TsdSearchParam } from "../fetch";

const productAttributeApi = createTsdApi<
  ProductAttribute,
  ProductAttributeQueryParams,
  ProductAttributeCreateParams,
  ProductAttributeUpdateParams
>("product-attributes", (searchParamsObject) => {
  const searchParams: TsdSearchParam<ProductAttributeQueryParams>[] = [];
  ({
    isActive: (() => {
      if (typeof searchParamsObject.isActive !== "undefined") {
        searchParams.push({
          key: "isActive",
          value: searchParamsObject.isActive ? "1" : "0",
        });
      }
    })(),
    names: (() => {
      if (typeof searchParamsObject.names !== "undefined") {
        searchParamsObject.names.forEach((name) => {
          searchParams.push({
            key: "names",
            value: name,
          });
        });
      }
    })(),
    productAttributeGroupIds: (() => {
      if (typeof searchParamsObject.productAttributeGroupIds !== "undefined") {
        searchParamsObject.productAttributeGroupIds.forEach((id) =>
          searchParams.push({
            key: "productAttributeGroupIds",
            value: String(id),
          })
        );
      }
    })(),
  }) satisfies {
    [key in keyof Required<ProductAttributeQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default productAttributeApi;
