import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerNoteAttributeValue,
  CustomerNoteAttributeValueCreateParams,
  CustomerNoteAttributeValueQueryParams,
  CustomerNoteAttributeValueUpdateParams,
} from "../../../entities/CustomerNoteAttributeValue/customerNoteAttributeValue";
import { TsdSearchParam } from "../fetch";

const customerNoteAttributeValueApi = createTsdApi<
  CustomerNoteAttributeValue,
  CustomerNoteAttributeValueQueryParams,
  CustomerNoteAttributeValueCreateParams,
  CustomerNoteAttributeValueUpdateParams
>("customer-note-attribute-values", (params) => {
  const search: TsdSearchParam<CustomerNoteAttributeValueQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
    customerNoteIds: (() => {
      if (typeof params.customerNoteIds !== "undefined") {
        params.customerNoteIds.forEach((id) => {
          search.push({ key: "customerNoteIds", value: String(id) });
        });
      }
    })(),
    customerNoteTagAttributeIds: (() => {
      if (typeof params.customerNoteTagAttributeIds !== "undefined") {
        params.customerNoteTagAttributeIds.forEach((id) => {
          search.push({
            key: "customerNoteTagAttributeIds",
            value: String(id),
          });
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        search.push({ key: "isDeleted", value: params.isDeleted ? "1" : "0" });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CustomerNoteAttributeValueQueryParams>]:
      | void
      | undefined;
  };

  return search;
});

export default customerNoteAttributeValueApi;
