import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerAnalysis,
  CustomerAnalysisCreateParams,
  CustomerAnalysisQueryParams,
  CustomerAnalysisUpdateParams,
} from "../../../entities/CustomerAnalysis/customerAnalysis";
import { TsdSearchParam } from "../fetch";

const customerAnalysisApi = createTsdApi<
  CustomerAnalysis,
  CustomerAnalysisQueryParams,
  CustomerAnalysisCreateParams,
  CustomerAnalysisUpdateParams
>("customer-analysis", (params) => {
  const search: TsdSearchParam<CustomerAnalysisQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
    customerIds: (() => {
      if (typeof params.customerIds !== "undefined") {
        params.customerIds.forEach((id) => {
          search.push({ key: "customerIds", value: String(id) });
        });
      }
    })(),
    customerGroupIds: (() => {
      if (typeof params.customerGroupIds !== "undefined") {
        params.customerGroupIds.forEach((id) => {
          search.push({ key: "customerGroupIds", value: String(id) });
        });
      }
    })(),
    year: (() => {
      if (typeof params.year !== "undefined") {
        search.push({ key: "year", value: String(params.year) });
      }
    })(),
    month: (() => {
      if (typeof params.month !== "undefined") {
        search.push({ key: "month", value: String(params.month) });
      }
    })(),
    budgetOrCategoryCategoryId: (() => {
      if (typeof params.budgetOrCategoryCategoryId !== "undefined") {
        search.push({
          key: "budgetOrCategoryCategoryId",
          value: String(params.budgetOrCategoryCategoryId),
        });
      }
    })(),
    budgetOrCategoryBudgetId: (() => {
      if (typeof params.budgetOrCategoryBudgetId !== "undefined") {
        search.push({
          key: "budgetOrCategoryBudgetId",
          value: String(params.budgetOrCategoryBudgetId),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CustomerAnalysisQueryParams>]: void | undefined;
  };

  return search;
});

export default customerAnalysisApi;
