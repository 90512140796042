import createTsdApi from "../../../client-api/createTsdApi";
import {
  ProductSizeOption,
  ProductSizeOptionCreateParams,
  ProductSizeOptionQueryParams,
  ProductSizeOptionUpdateParams,
} from "../../../entities/ProductSizeOption/productSizeOption";
import { TsdSearchParam } from "../fetch";

const productSizeOptionApi = createTsdApi<
  ProductSizeOption,
  ProductSizeOptionQueryParams,
  ProductSizeOptionCreateParams,
  ProductSizeOptionUpdateParams
>("product-size-options", (params) => {
  const searchParams: TsdSearchParam<ProductSizeOptionQueryParams>[] = [];

  ({
    productId: (() => {
      if (typeof params.productId !== "undefined") {
        searchParams.push({
          key: "productId",
          value: String(params.productId),
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: params.isDeleted ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ProductSizeOptionQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default productSizeOptionApi;
