import { User } from "store/auth/types";

type JwtPayload = {
  exp?: number;
  iat: number;
  iss: string;
  user: User;
};

/**
 * Returns true if exp is valid otherwise false
 * @param currentTime Date object or time in seconds
 * @param exp
 */
const isJwtExpValid = (currentTime: Date | number, exp: JwtPayload["exp"]) => {
  let time = null;
  if (currentTime instanceof Date) {
    time = currentTime.getTime() / 1000;
  } else {
    time = currentTime;
  }
  return !exp || exp > time;
};

const getLoggedInUser = (jwtCookie: string) => {
  const [, payloadEncoded] = jwtCookie.split(".");
  const base64 = payloadEncoded.replace(/-/g, "+").replace(/_/g, "/");
  const stringPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join("")
  );

  const payload = JSON.parse(stringPayload) as JwtPayload;

  const isJwtValid = isJwtExpValid(new Date(), payload.exp);

  return isJwtValid ? payload.user : null;
};

export default getLoggedInUser;
