import createTsdApi from "../../../client-api/createTsdApi";
import {
  PrebuiltOrderItem,
  PrebuiltOrderItemQueryParams,
  PrebuiltOrderItemCreateParams,
  PrebuiltOrderItemUpdateParams,
} from "../../../entities/prebuiltOrderItem";
import { TsdSearchParam } from "../fetch";

const prebuiltOrderItemApi = createTsdApi<
  PrebuiltOrderItem,
  PrebuiltOrderItemQueryParams,
  PrebuiltOrderItemCreateParams,
  PrebuiltOrderItemUpdateParams
>("prebuilt-order-items", (searchParamsObject) => {
  let searchParams: TsdSearchParam<PrebuiltOrderItemQueryParams>[] = [];

  ({
    prebuiltOrderId: (() => {
      if (typeof searchParamsObject.prebuiltOrderId !== "undefined") {
        searchParams.push({
          key: "prebuiltOrderId",
          value: String(searchParamsObject.prebuiltOrderId),
        });
      }
    })(),
    prebuiltOrderIds: (() => {
      if (typeof searchParamsObject.prebuiltOrderIds !== "undefined") {
        searchParamsObject.prebuiltOrderIds.forEach((id) => {
          searchParams.push({
            key: "prebuiltOrderIds",
            value: String(id),
          });
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<PrebuiltOrderItemQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default prebuiltOrderItemApi;
