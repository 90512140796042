import createTsdApi from "../../createTsdApi";
import { TsdSearchParam } from "../fetch";
import {
  ReportRecipient,
  ReportRecipientCreateParams,
  ReportRecipientQueryParams,
  ReportRecipientUpdateParams,
} from "../../../entities/reportRecipient";

const reportRecipientApi = createTsdApi<
  ReportRecipient,
  ReportRecipientQueryParams,
  ReportRecipientCreateParams,
  ReportRecipientUpdateParams
>("report-recipients", (params) => {
  const searchParams: TsdSearchParam<ReportRecipientQueryParams>[] = [];

  ({
    reportId: (() => {
      if (typeof params.reportId !== "undefined") {
        searchParams.push({ key: "reportId", value: String(params.reportId) });
      }
    })(),
    reportIds: (() => {
      if (typeof params.reportIds !== "undefined") {
        params.reportIds.forEach((id) => {
          searchParams.push({ key: "reportIds", value: String(id) });
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({ key: "offset", value: String(params.offset) });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ReportRecipientQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default reportRecipientApi;
