import createTsdApi from "../../../client-api/createTsdApi";
import {
  Supplier,
  SupplierQueryParams,
  SupplierCreateParams,
  SupplierUpdateParams,
} from "../../../entities/supplier";
import { TsdSearchParam } from "../fetch";

const supplierApi = createTsdApi<
  Supplier,
  SupplierQueryParams,
  SupplierCreateParams,
  SupplierUpdateParams
>("suppliers", (searchParamsObject) => {
  let searchParams: TsdSearchParam<SupplierQueryParams>[] = [];
  ({
    isActive: (() => {
      if (typeof searchParamsObject.isActive !== "undefined") {
        searchParams.push({
          key: "isActive",
          value: searchParamsObject.isActive ? "1" : "0",
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof searchParamsObject.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: searchParamsObject.isDeleted ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    searchTerm: (() => {
      if (typeof searchParamsObject.searchTerm !== "undefined") {
        searchParams.push({
          key: "searchTerm",
          value: searchParamsObject.searchTerm,
        });
      }
    })(),
    addressSearchTerm: (() => {
      if (typeof searchParamsObject.addressSearchTerm !== "undefined") {
        searchParams.push({
          key: "addressSearchTerm",
          value: searchParamsObject.addressSearchTerm,
        });
      }
    })(),
    contactSearchTerm: (() => {
      if (typeof searchParamsObject.contactSearchTerm !== "undefined") {
        searchParams.push({
          key: "contactSearchTerm",
          value: searchParamsObject.contactSearchTerm,
        });
      }
    })(),
    isArchived: (() => {
      if (typeof searchParamsObject.isArchived !== "undefined") {
        searchParams.push({
          key: "isArchived",
          value: searchParamsObject.isArchived ? "1" : "0",
        });
      }
    })(),
    isPrivateSupplier: (() => {
      if (typeof searchParamsObject.isPrivateSupplier !== "undefined") {
        searchParams.push({
          key: "isPrivateSupplier",
          value: searchParamsObject.isPrivateSupplier ? "1" : "0",
        });
      }
    })(),
    customerIdsWithAccess: (() => {
      if (typeof searchParamsObject.customerIdsWithAccess !== "undefined") {
        searchParamsObject.customerIdsWithAccess.forEach((id) => {
          searchParams.push({
            key: "customerIdsWithAccess",
            value: String(id),
          });
        });
      }
    })(),
    isAccessEnabledOnly: (() => {
      if (typeof searchParamsObject.isAccessEnabledOnly !== "undefined") {
        searchParams.push({
          key: "isAccessEnabledOnly",
          value: searchParamsObject.isAccessEnabledOnly ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<SupplierQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default supplierApi;
