import dayjs from "dayjs";
import createTsdApi from "../../../client-api/createTsdApi";
import {
  IDiaryItem,
  IDiaryItemCreateParams,
  IDiaryItemQueryParams,
  IDiaryItemUpdateParams,
} from "../../../entities/diaryItem";
import { TsdSearchParam } from "../fetch";

const diaryItemApi = createTsdApi<
  IDiaryItem,
  IDiaryItemQueryParams,
  IDiaryItemCreateParams,
  IDiaryItemUpdateParams
>("diary-items", (searchParamsObject) => {
  let searchParams: TsdSearchParam<IDiaryItemQueryParams>[] = [];

  ({
    isActive: (() => {
      if (typeof searchParamsObject.isActive !== "undefined") {
        searchParams.push({
          key: "isActive",
          value: searchParamsObject.isActive ? "1" : "0",
        });
      }
    })(),
    type: (() => {
      if (typeof searchParamsObject.type !== "undefined") {
        searchParamsObject.type.forEach((type) => {
          searchParams.push({
            key: "type",
            value: type,
          });
        });
      }
    })(),
    orgId: (() => {
      if (typeof searchParamsObject.orgId !== "undefined") {
        searchParams.push({
          key: "orgId",
          value: String(searchParamsObject.orgId),
        });
      }
    })(),
    orgIds: (() => {
      if (typeof searchParamsObject.orgIds != "undefined") {
        searchParamsObject.orgIds.forEach((id) => {
          searchParams.push({
            key: "orgIds",
            value: String(id),
          });
        });
      }
    })(),
    callWeekday: (() => {
      if (typeof searchParamsObject.callWeekday !== "undefined") {
        searchParams.push({
          key: "callWeekday",
          value: String(searchParamsObject.callWeekday),
        });
      }
    })(),
    deadlineWeekday: (() => {
      if (typeof searchParamsObject.deadlineWeekday !== "undefined") {
        searchParams.push({
          key: "deadlineWeekday",
          value: String(searchParamsObject.deadlineWeekday),
        });
      }
    })(),
    startDateGte: (() => {
      if (typeof searchParamsObject.startDateGte !== "undefined") {
        searchParams.push({
          key: "startDateGte",
          value: dayjs()
            .fromTsdDateFormat(searchParamsObject.startDateGte)
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    startDateLte: (() => {
      if (typeof searchParamsObject.startDateLte !== "undefined") {
        searchParams.push({
          key: "startDateLte",
          value: dayjs()
            .fromTsdDateFormat(searchParamsObject.startDateLte)
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    jobId: (() => {
      if (typeof searchParamsObject.jobId !== "undefined") {
        searchParams.push({
          key: "jobId",
          value: String(searchParamsObject.jobId),
        });
      }
    })(),
    categoryId: (() => {
      if (typeof searchParamsObject.categoryId !== "undefined") {
        searchParams.push({
          key: "categoryId",
          value: String(searchParamsObject.categoryId),
        });
      }
    })(),
    productOriginalId: (() => {
      if (typeof searchParamsObject.productOriginalId !== "undefined") {
        searchParams.push({
          key: "productOriginalId",
          value: String(searchParamsObject.productOriginalId),
        });
      }
    })(),
    hold: (() => {
      if (typeof searchParamsObject.hold !== "undefined") {
        searchParams.push({
          key: "hold",
          value: searchParamsObject.hold ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    ids: (() => {
      if (typeof searchParamsObject.ids != "undefined") {
        searchParamsObject.ids.forEach((id) => {
          searchParams.push({
            key: "ids",
            value: String(id),
          });
        });
      }
    })(),
    userId: (() => {
      if (typeof searchParamsObject.userId !== "undefined") {
        searchParams.push({
          key: "userId",
          value: String(searchParamsObject.userId),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<IDiaryItemQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default diaryItemApi;
