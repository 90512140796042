import createTsdApi from "../../../client-api/createTsdApi";
import {
  Page,
  PageCreateParams,
  PageQueryParams,
  PageUpdateParams,
} from "../../../entities/Page/page";
import { TsdSearchParam } from "../fetch";

const pageApi = createTsdApi<
  Page,
  PageQueryParams,
  PageCreateParams,
  PageUpdateParams
>("pages", (params) => {
  const searchParams: TsdSearchParam<PageQueryParams>[] = [];

  if (typeof params.name !== "undefined") {
    searchParams.push({ key: "name", value: params.name });
  }

  if (typeof params.relativeUrl !== "undefined") {
    searchParams.push({ key: "relativeUrl", value: params.relativeUrl });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({ key: "limit", value: String(params.limit) });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({ key: "offset", value: String(params.offset) });
  }

  return searchParams;
});

export default pageApi;
