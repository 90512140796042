import chunkArray from "../../../helpers/chunkArray";
import createTsdApi from "../../../client-api/createTsdApi";
import {
  ProductFavourite,
  ProductFavouriteQueryParams,
  ProductFavouriteCreateParams,
  ProductFavouriteUpdateParams,
} from "../../../entities/productFavourite";
import tsdFetch, { TsdSearchParam } from "../fetch";

const productFavouriteApi = createTsdApi<
  ProductFavourite,
  ProductFavouriteQueryParams,
  ProductFavouriteCreateParams,
  ProductFavouriteUpdateParams
>("product-favourites", (searchParamsObject) => {
  let searchParams: TsdSearchParam<ProductFavouriteQueryParams>[] = [];

  ({
    customerIds: (() => {
      if (typeof searchParamsObject.customerIds !== "undefined") {
        searchParamsObject.customerIds.forEach((id) => {
          searchParams.push({
            key: "customerIds",
            value: String(id),
          });
        });
      }
    })(),
    supplierIds: (() => {
      if (typeof searchParamsObject.supplierIds !== "undefined") {
        searchParamsObject.supplierIds.forEach((id) => {
          searchParams.push({
            key: "supplierIds",
            value: String(id),
          });
        });
      }
    })(),
    productIds: (() => {
      if (typeof searchParamsObject.productIds !== "undefined") {
        searchParamsObject.productIds.forEach((id) => {
          searchParams.push({
            key: "productIds",
            value: String(id),
          });
        });
      }
    })(),
    groupBy: (() => {
      if (typeof searchParamsObject.groupBy !== "undefined") {
        searchParams.push({
          key: "groupBy",
          value: searchParamsObject.groupBy,
        });
      }
    })(),
    productOriginalIds: (() => {
      if (typeof searchParamsObject.productOriginalIds !== "undefined") {
        searchParamsObject.productOriginalIds.forEach((id) => {
          searchParams.push({
            key: "productOriginalIds",
            value: String(id),
          });
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ProductFavouriteQueryParams>]: void | undefined;
  };

  return searchParams;
});

const bulkCreate = (newProductFavourites: ProductFavouriteCreateParams[]) => {
  const url = "api/v1/bulk/add-product-favourites";

  const chunks = chunkArray(newProductFavourites, 1000000);

  for (const chunk of chunks) {
    tsdFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(chunk),
    });
  }
};

const bulkDelete = (ids: number[]) => {
  const url = "api/v1/bulk/delete-product-favourites";

  const chunks = chunkArray(ids, 1000000);

  for (const chunk of chunks) {
    tsdFetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(chunk),
    });
  }
};

export default { bulkCreate, bulkDelete, ...productFavouriteApi };
