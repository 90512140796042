import createTsdApi from "../../../client-api/createTsdApi";
import {
  ConsolidatedInvoice,
  ConsolidatedInvoiceCreateParams,
  ConsolidatedInvoiceQueryParams,
  ConsolidatedInvoiceUpdateParams,
} from "../../../entities/ConsolidatedInvoice/consolidatedInvoice";
import { TsdSearchParam } from "../fetch";

const consolidatedInvoiceApi = createTsdApi<
  ConsolidatedInvoice,
  ConsolidatedInvoiceQueryParams,
  ConsolidatedInvoiceCreateParams,
  ConsolidatedInvoiceUpdateParams
>("consolidated-invoices", (params) => {
  const search: TsdSearchParam<ConsolidatedInvoiceQueryParams>[] = [];

  ({
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) =>
          search.push({ key: "ids", value: String(id) })
        );
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ConsolidatedInvoiceQueryParams>]: void | undefined;
  };

  return search;
});

export default { get: consolidatedInvoiceApi.get, getOne: consolidatedInvoiceApi.getOne };
