import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthServices } from "services";
import Cookie from "js-cookie";
import { User } from "store/auth/types";

type AuthState = {
  isLoggedIn: boolean;
  isAuthLoading: boolean;
  user: User | null;
};

type LoginProps = {
  username: string;
  password: string;
};

const jwt = Cookie.get("jwt");
const user = jwt ? AuthServices.getLoggedInUser(jwt) : null;

const initialState: AuthState = user
  ? { isLoggedIn: true, user, isAuthLoading: false }
  : { isLoggedIn: false, user: null, isAuthLoading: true };

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }: LoginProps, thunkAPI) => {
    try {
      return await AuthServices.login({ username, password });
    } catch (e) {
      return thunkAPI.rejectWithValue(
        e instanceof Error ? e.message : "Login failed"
      );
    }
  }
);

export const logout = createAsyncThunk("auth/logout", () => {
  AuthServices.logout();
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.isAuthLoading = false;
      state.user = action.payload;
    });

    builder.addCase(login.rejected, (state) => {
      state.isLoggedIn = false;
      state.isAuthLoading = false;
      state.user = null;
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.isAuthLoading = false;
      state.isLoggedIn = true;
      state.user = null;
    });
  },
});

export default authSlice.reducer;
