import { stringify } from "csv-stringify/browser/esm";
import dayjs from "dayjs";

type CsvHeaders<T> = {
  [key in keyof Partial<T>]: string;
};

type Options = {
  filename: string;
  timestamp: boolean;
};

const defaultOptions: Options = { filename: "csv_export", timestamp: true };

const createAndDownloadCsv = <T>(
  array: T[],
  headers: CsvHeaders<T>,
  csvOptions?: Partial<Options>
) => {
  const options = { ...defaultOptions, ...csvOptions };

  stringify(
    array,
    {
      header: true,
      columns: headers,
      quote: true,
      quoted: true,
      quoted_empty: true,
      cast: {
        date: (date) => dayjs(date).format("DD/MM/YYYY HH:mm"),
      },
    },
    (e, data) => {
      const encodedUri = encodeURI(
        "data:text/csv;charset=utf-8," + data
      ).replaceAll("#", "%23");
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `${options.filename}${
          options.timestamp ? `-${dayjs().format("DD-MM-YYYY HH-mm-ss")}` : ""
        }.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  );
};

export default createAndDownloadCsv;
