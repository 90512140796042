import createTsdApi from "../../../client-api/createTsdApi";
import {
  DetailLog,
  DetailLogCreateParams,
  DetailLogQueryParams,
  DetailLogUpdateParams,
} from "../../../entities/DetailLog/detailLog";
import { TsdSearchParam } from "../fetch";

const detailLogApi = createTsdApi<
  DetailLog,
  DetailLogQueryParams,
  DetailLogCreateParams,
  DetailLogUpdateParams
>("detail-logs", (params) => {
  const search: TsdSearchParam<DetailLogQueryParams>[] = [];

  ({
    userId: (() => {
      if (typeof params.userId !== "undefined") {
        search.push({
          key: "userId",
          value: String(params.userId),
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
  }) satisfies {
    [key in keyof Required<DetailLogQueryParams>]: void | undefined;
  };

  return search;
});

export default detailLogApi;
