import createTsdApi from "../../createTsdApi";
import {
  ProductAttributeProduct,
  ProductAttributeProductCreateParams,
  ProductAttributeProductQueryParams,
  ProductAttributeProductUpdateParams,
} from "../../../entities/ProductAttributeProduct/productAttributeProduct";
import { TsdSearchParam } from "../fetch";

const productAttributeProductApi = createTsdApi<
  ProductAttributeProduct,
  ProductAttributeProductQueryParams,
  ProductAttributeProductCreateParams,
  ProductAttributeProductUpdateParams
>("product-attribute-products", (searchParamsObject) => {
  const searchParams: TsdSearchParam<ProductAttributeProductQueryParams>[] = [];

  ({
    productAttributeIds: (() => {
      if (typeof searchParamsObject.productAttributeIds !== "undefined") {
        searchParamsObject.productAttributeIds.forEach((id) => {
          searchParams.push({
            key: "productAttributeIds",
            value: String(id),
          });
        });
      }
    })(),
    productIds: (() => {
      if (typeof searchParamsObject.productIds !== "undefined") {
        searchParamsObject.productIds.forEach((id) => {
          searchParams.push({
            key: "productIds",
            value: String(id),
          });
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ProductAttributeProductQueryParams>]:
      | void
      | undefined;
  };

  return searchParams;
});

export default productAttributeProductApi;
