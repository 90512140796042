import createTsdApi from "../../../../src/client-api/createTsdApi";
import {
  JobGroup,
  JobGroupCreateParams,
  JobGroupQueryParams,
  JobGroupUpdateParams,
} from "../../../../src/entities/jobGroup";
import { TsdSearchParam } from "../fetch";

const jobGroupApi = createTsdApi<
  JobGroup,
  JobGroupQueryParams,
  JobGroupCreateParams,
  JobGroupUpdateParams
>("job-groups", (searchParamsObject) => {
  let searchParams: TsdSearchParam<JobGroupQueryParams>[] = [];

  ({
    isDraft: (() => {
      if (typeof searchParamsObject.isDraft !== "undefined") {
        searchParams.push({
          key: "isDraft",
          value: searchParamsObject.isDraft ? "1" : "0",
        });
      }
    })(),
    isConfirmed: (() => {
      if (typeof searchParamsObject.isConfirmed !== "undefined") {
        searchParams.push({
          key: "isConfirmed",
          value: searchParamsObject.isConfirmed ? "1" : "0",
        });
      }
    })(),
    isCancelled: (() => {
      if (typeof searchParamsObject.isCancelled !== "undefined") {
        searchParams.push({
          key: "isCancelled",
          value: searchParamsObject.isCancelled ? "1" : "0",
        });
      }
    })(),
    isAuthorisationApproved: (() => {
      if (typeof searchParamsObject.isAuthorisationApproved !== "undefined") {
        searchParams.push({
          key: "isAuthorisationApproved",
          value: searchParamsObject.isAuthorisationApproved ? "1" : "0",
        });
      }
    })(),
    isAuthorisationRejected: (() => {
      if (typeof searchParamsObject.isAuthorisationRejected !== "undefined") {
        searchParams.push({
          key: "isAuthorisationRejected",
          value: searchParamsObject.isAuthorisationRejected ? "1" : "0",
        });
      }
    })(),
    isAuthorisationPending: (() => {
      if (typeof searchParamsObject.isAuthorisationPending !== "undefined") {
        searchParams.push({
          key: "isAuthorisationPending",
          value: searchParamsObject.isAuthorisationPending ? "1" : "0",
        });
      }
    })(),
    isAuthorisationSent: (() => {
      if (typeof searchParamsObject.isAuthorisationSent !== "undefined") {
        searchParams.push({
          key: "isAuthorisationSent",
          value: searchParamsObject.isAuthorisationSent ? "1" : "0",
        });
      }
    })(),
    authorisationRequestedDate: (() => {
      if (
        typeof searchParamsObject.authorisationRequestedDate !== "undefined"
      ) {
        searchParams.push({
          key: "authorisationRequestedDate",
          value: String(searchParamsObject.authorisationRequestedDate),
        });
      }
    })(),
    authorisationRequestedDateGte: (() => {
      if (
        typeof searchParamsObject.authorisationRequestedDateGte !== "undefined"
      ) {
        searchParams.push({
          key: "authorisationRequestedDateGte",
          value: searchParamsObject.authorisationRequestedDateGte,
        });
      }
    })(),
    created: (() => {
      if (typeof searchParamsObject.created !== "undefined") {
        searchParams.push({
          key: "created",
          value: searchParamsObject.created,
        });
      }
    })(),
    createdGte: (() => {
      if (typeof searchParamsObject.createdGte !== "undefined") {
        searchParams.push({
          key: "createdGte",
          value: searchParamsObject.createdGte,
        });
      }
    })(),
    createdLte: (() => {
      if (typeof searchParamsObject.createdLte !== "undefined") {
        searchParams.push({
          key: "createdLte",
          value: searchParamsObject.createdLte,
        });
      }
    })(),
    hasRequestAuthorisationEvent: (() => {
      if (
        typeof searchParamsObject.hasRequestAuthorisationEvent !== "undefined"
      ) {
        searchParams.push({
          key: "hasRequestAuthorisationEvent",
          value: searchParamsObject.hasRequestAuthorisationEvent ? "1" : "0",
        });
      }
    })(),
    orderNeedsReconfirmed: (() => {
      if (typeof searchParamsObject.orderNeedsReconfirmed !== "undefined") {
        searchParams.push({
          key: "orderNeedsReconfirmed",
          value: searchParamsObject.orderNeedsReconfirmed ? "1" : "0",
        });
      }
    })(),
    confirmedDateTimeGte: (() => {
      if (typeof searchParamsObject.confirmedDateTimeGte !== "undefined") {
        searchParams.push({
          key: "confirmedDateTimeGte",
          value: searchParamsObject.confirmedDateTimeGte,
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    ids: (() => {
      if (typeof searchParamsObject.ids !== "undefined") {
        searchParamsObject.ids.forEach((id) => {
          searchParams.push({
            key: "ids",
            value: String(id),
          });
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<JobGroupQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default jobGroupApi;
