import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerRequestCorrespondence,
  CustomerRequestCorrespondenceCreateParams,
  CustomerRequestCorrespondenceQueryParams,
  CustomerRequestCorrespondenceUpdateParams,
} from "../../../entities/customerRequestCorrespondence";
import { TsdSearchParam } from "../fetch";

const customerRequestCorrespondenceApi = createTsdApi<
  CustomerRequestCorrespondence,
  CustomerRequestCorrespondenceQueryParams,
  CustomerRequestCorrespondenceCreateParams,
  CustomerRequestCorrespondenceUpdateParams
>("customer-request-correspondences", (params) => {
  const search: TsdSearchParam<CustomerRequestCorrespondenceQueryParams>[] = [];

  ({
    requestIds: (() => {
      if (typeof params.requestIds !== "undefined") {
        params.requestIds.forEach((id) => {
          search.push({
            key: "requestIds",
            value: String(id),
          });
        });
      }
    })(),

    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CustomerRequestCorrespondenceQueryParams>]:
      | void
      | undefined;
  };

  return search;
});

export default customerRequestCorrespondenceApi;
