import {
  BudgetProduct,
  BudgetProductCreateParams,
  BudgetProductQueryParams,
  BudgetProductUpdateParams,
} from "../../../entities/BudgetProduct/budgetProduct";
import createTsdApi from "../../../client-api/createTsdApi";
import { TsdSearchParam } from "../fetch";
import dayjs from "dayjs";

const budgetProductApi = createTsdApi<
  BudgetProduct,
  BudgetProductQueryParams,
  BudgetProductCreateParams,
  BudgetProductUpdateParams
>("budget-products", (params) => {
  const searchParams: TsdSearchParam<BudgetProductQueryParams>[] = [];

  ({
    budgetIds: (() => {
      if (typeof params.budgetIds !== "undefined") {
        params.budgetIds.forEach((id) => {
          searchParams.push({
            key: "budgetIds",
            value: String(id),
          });
        });
      }
    })(),
    productOriginalIds: (() => {
      if (typeof params.productOriginalIds !== "undefined") {
        params.productOriginalIds.forEach((id) => {
          searchParams.push({
            key: "productOriginalIds",
            value: String(id),
          });
        });
      }
    })(),
    statuses: (() => {
      if (typeof params.statuses !== "undefined") {
        params.statuses.forEach((status) => {
          searchParams.push({
            key: "statuses",
            value: status,
          });
        });
      }
    })(),
    finalDateGteOrNull: (() => {
      if (typeof params.finalDateGteOrNull !== "undefined") {
        searchParams.push({
          key: "finalDateGteOrNull",
          value: dayjs()
            .fromTsdDateFormat(params.finalDateGteOrNull)
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    startDateLte: (() => {
      if (typeof params.startDateLte !== "undefined") {
        searchParams.push({
          key: "startDateLte",
          value: dayjs()
            .fromTsdDateFormat(params.startDateLte)
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    isCurrent: (() => {
      if (typeof params.isCurrent !== "undefined") {
        searchParams.push({
          key: "isCurrent",
          value: params.isCurrent ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<BudgetProductQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default budgetProductApi;
