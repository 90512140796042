import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerRequest,
  CustomerRequestCreateParams,
  CustomerRequestQueryParams,
  CustomerRequestUpdateParams,
} from "../../../entities/customerRequest";
import { TsdSearchParam } from "../fetch";

const customerRequestApi = createTsdApi<
  CustomerRequest,
  CustomerRequestQueryParams,
  CustomerRequestCreateParams,
  CustomerRequestUpdateParams
>("customer-requests", (params) => {
  const search: TsdSearchParam<CustomerRequestQueryParams>[] = [];

  ({
    created: (() => {
      if (typeof params.created !== "undefined") {
        search.push({
          key: "created",
          value: params.created,
        });
      }
    })(),
    createdLte: (() => {
      if (typeof params.createdLte !== "undefined") {
        search.push({
          key: "createdLte",
          value: params.createdLte,
        });
      }
    })(),
    createdGte: (() => {
      if (typeof params.createdGte !== "undefined") {
        search.push({
          key: "createdGte",
          value: params.createdGte,
        });
      }
    })(),
    result: (() => {
      if (typeof params.result !== "undefined") {
        search.push({
          key: "result",
          value: params.result,
        });
      }
    })(),
    customerIds: (() => {
      if (typeof params.customerIds !== "undefined") {
        params.customerIds.forEach((id) => {
          search.push({
            key: "customerIds",
            value: String(id),
          });
        });
      }
    })(),
    supplierIds: (() => {
      if (typeof params.supplierIds !== "undefined") {
        params.supplierIds.forEach((id) => {
          search.push({
            key: "supplierIds",
            value: String(id),
          });
        });
      }
    })(),
    typeIds: (() => {
      if (typeof params.typeIds !== "undefined") {
        params.typeIds.forEach((id) => {
          search.push({
            key: "typeIds",
            value: String(id),
          });
        });
      }
    })(),
    jobIds: (() => {
      if (typeof params.jobIds !== "undefined") {
        params.jobIds.forEach((id) => {
          search.push({
            key: "jobIds",
            value: String(id),
          });
        });
      }
    })(),
    isUrgent: (() => {
      if (typeof params.isUrgent !== "undefined") {
        search.push({
          key: "isUrgent",
          value: params.isUrgent ? "1" : "0",
        });
      }
    })(),
    isActive: (() => {
      if (typeof params.isActive !== "undefined") {
        search.push({
          key: "isActive",
          value: params.isActive ? "1" : "0",
        });
      }
    })(),
    isInactive: (() => {
      if (typeof params.isInactive !== "undefined") {
        search.push({
          key: "isInactive",
          value: params.isInactive ? "1" : "0",
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        search.push({
          key: "isDeleted",
          value: params.isDeleted ? "1" : "0",
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CustomerRequestQueryParams>]: void | undefined;
  };

  return search;
});

export default customerRequestApi;
