import {
  NewSession,
  NewSessionResponse,
} from "../../../../entities/session/new";
import tsdFetch, { ITsdFetch } from "../../fetch";

type CreateFunc = (entity: NewSession) => ITsdFetch<NewSessionResponse>;

const createOne: CreateFunc = async (entity) => {
  const url = `/api/v1/sessions/new/`;
  return tsdFetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(entity),
  });
};

export default createOne;
