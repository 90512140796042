import createTsdApi from "../../../client-api/createTsdApi";
import {
  RelatedProduct,
  RelatedProductCreateParams,
  RelatedProductQueryParams,
  RelatedProductUpdateParams,
} from "../../../entities/RelatedProduct/relatedProduct";
import { TsdSearchParam } from "../fetch";

const relatedProductApi = createTsdApi<
  RelatedProduct,
  RelatedProductQueryParams,
  RelatedProductCreateParams,
  RelatedProductUpdateParams
>("related-products", (searchParamsObject) => {
  let searchParams: TsdSearchParam<RelatedProductQueryParams>[] = [];

  ({
    createdGte: (() => {
      if (typeof searchParamsObject.createdGte !== "undefined") {
        searchParams.push({
          key: "createdGte",
          value: searchParamsObject.createdGte.toISOString(),
        });
      }
    })(),
    createdLte: (() => {
      if (typeof searchParamsObject.createdLte !== "undefined") {
        searchParams.push({
          key: "createdLte",
          value: searchParamsObject.createdLte.toISOString(),
        });
      }
    })(),
    targetProductOriginalIds: (() => {
      if (typeof searchParamsObject.targetProductOriginalIds !== "undefined") {
        searchParams.push({
          key: "targetProductOriginalIds",
          value: String(searchParamsObject.targetProductOriginalIds),
        });
      }
    })(),
    relatedProductOriginalIds: (() => {
      if (typeof searchParamsObject.relatedProductOriginalIds !== "undefined") {
        searchParams.push({
          key: "relatedProductOriginalIds",
          value: String(searchParamsObject.relatedProductOriginalIds),
        });
      }
    })(),
    isSimilar: (() => {
      if (typeof searchParamsObject.isSimilar !== "undefined") {
        searchParams.push({
          key: "isSimilar",
          value: searchParamsObject.isSimilar ? "1" : "0",
        });
      }
    })(),
    isAlternative: (() => {
      if (typeof searchParamsObject.isAlternative !== "undefined") {
        searchParams.push({
          key: "isAlternative",
          value: searchParamsObject.isAlternative ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<RelatedProductQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default relatedProductApi;
