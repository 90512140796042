import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerPersonalFavouriteProductCode,
  CustomerPersonalFavouriteProductCodeCreateParams,
  CustomerPersonalFavouriteProductCodeQueryParams,
  CustomerPersonalFavouriteProductCodeUpdateParams,
} from "../../../entities/CustomerPersonalFavouriteProductCode/customerPersonalFavouriteProductCode";
import { TsdSearchParam } from "../fetch";

const customerPersonalFavouriteProductCodeApi = createTsdApi<
  CustomerPersonalFavouriteProductCode,
  CustomerPersonalFavouriteProductCodeQueryParams,
  CustomerPersonalFavouriteProductCodeCreateParams,
  CustomerPersonalFavouriteProductCodeUpdateParams
>("customer-personal-favourite-product-codes", (params) => {
  const search: TsdSearchParam<CustomerPersonalFavouriteProductCodeQueryParams>[] =
    [];

  ({
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
    orgIds: (() => {
      if (typeof params.orgIds !== "undefined") {
        params.orgIds.forEach((id) =>
          search.push({ key: "orgIds", value: String(id) })
        );
      }
    })(),
    productCodes: (() => {
      if (typeof params.productCodes !== "undefined") {
        params.productCodes.forEach((productCode) =>
          search.push({ key: "productCodes", value: String(productCode) })
        );
      }
    })(),
  }) satisfies {
    [key in keyof Required<CustomerPersonalFavouriteProductCodeQueryParams>]:
      | void
      | undefined;
  };

  return search;
});

export default customerPersonalFavouriteProductCodeApi;
