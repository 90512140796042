import createTsdApi from "../../../client-api/createTsdApi";
import {
  PriceFile,
  PriceFileCreateParams,
  PriceFileQueryParams,
  PriceFileUpdateParams,
} from "../../../entities/priceFile";
import { TsdSearchParam } from "../fetch";

const priceFileApi = createTsdApi<
  PriceFile,
  PriceFileQueryParams,
  PriceFileCreateParams,
  PriceFileUpdateParams
>("price-files", (searchParamsObject) => {
  const searchParams: TsdSearchParam<PriceFileQueryParams>[] = [];

  ({
    supplierIds: (() => {
      if (typeof searchParamsObject.supplierIds !== "undefined") {
        searchParamsObject.supplierIds.forEach((id) => {
          searchParams.push({
            key: "supplierIds",
            value: String(id),
          });
        });
      }
    })(),
    isDefault: (() => {
      if (typeof searchParamsObject.isDefault !== "undefined") {
        searchParams.push({
          key: "isDefault",
          value: searchParamsObject.isDefault ? "1" : "0",
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof searchParamsObject.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: searchParamsObject.isDeleted ? "1" : "0",
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    ids: (() => {
      if (typeof searchParamsObject.ids !== "undefined") {
        searchParamsObject.ids.forEach((id) => {
          searchParams.push({
            key: "ids",
            value: String(id),
          });
        });
      }
    })(),
    "guessPriceFile.customerId": (() => {
      if (
        typeof searchParamsObject["guessPriceFile.customerId"] !== "undefined"
      ) {
        searchParams.push({
          key: "guessPriceFile.customerId",
          value: String(searchParamsObject["guessPriceFile.customerId"]),
        });
      }
    })(),
    "guessPriceFile.supplierId": (() => {
      if (
        typeof searchParamsObject["guessPriceFile.supplierId"] !== "undefined"
      ) {
        searchParams.push({
          key: "guessPriceFile.supplierId",
          value: String(searchParamsObject["guessPriceFile.supplierId"]),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<PriceFileQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default priceFileApi;
