import createTsdApi from "../../../client-api/createTsdApi";
import {
  VatRate,
  VatRateQueryParams,
  VatRateCreateParams,
  VatRateUpdateParams,
} from "../../../entities/VatRate/vatRate";
import { TsdSearchParam } from "../fetch";

const vatRateApi = createTsdApi<
  VatRate,
  VatRateQueryParams,
  VatRateCreateParams,
  VatRateUpdateParams
>("vat-rates", (searchParamsObject) => {
  let searchParams: TsdSearchParam<VatRateQueryParams>[] = [];

  ({
    name: (() => {
      if (typeof searchParamsObject.name !== "undefined") {
        searchParams.push({
          key: "name",
          value: searchParamsObject.name,
        });
      }
    })(),
    isActive: (() => {
      if (typeof searchParamsObject.isActive !== "undefined") {
        searchParams.push({
          key: "isActive",
          value: searchParamsObject.isActive ? "1" : "0",
        });
      }
    })(),
    code: (() => {
      if (typeof searchParamsObject.code !== "undefined") {
        searchParams.push({
          key: "code",
          value: searchParamsObject.code,
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<VatRateQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default vatRateApi;
