import createTsdApi from "../../createTsdApi";
import {
  NotificationsLog,
  NotificationsLogCreateParams,
  NotificationsLogQueryParams,
  NotificationsLogUpdateParams,
} from "../../../entities/notificationsLog";
import { TsdSearchParam } from "../fetch";

const notificationsLogApi = createTsdApi<
  NotificationsLog,
  NotificationsLogQueryParams,
  NotificationsLogCreateParams,
  NotificationsLogUpdateParams
>("notifications-log", (searchParamsObject) => {
  let searchParams: TsdSearchParam<NotificationsLogQueryParams>[] = [];

  if (typeof searchParamsObject.orgIds !== "undefined") {
    searchParamsObject.orgIds.forEach((jobGroupId) => {
      searchParams.push({
        key: "orgIds",
        value: String(jobGroupId),
      });
    });
  }

  if (typeof searchParamsObject.jobGroupIds !== "undefined") {
    searchParamsObject.jobGroupIds.forEach((jobGroupId) => {
      searchParams.push({
        key: "jobGroupIds",
        value: String(jobGroupId),
      });
    });
  }

  if (typeof searchParamsObject.jobIds !== "undefined") {
    searchParamsObject.jobIds.forEach((jobGroupId) => {
      searchParams.push({
        key: "jobIds",
        value: String(jobGroupId),
      });
    });
  }

  if (typeof searchParamsObject.status !== "undefined") {
    searchParamsObject.status.forEach((status) => {
      searchParams.push({
        key: "status",
        value: status,
      });
    });
  }

  if (typeof searchParamsObject.description !== "undefined") {
    searchParams.push({
      key: "description",
      value: searchParamsObject.description,
    });
  }

  if (typeof searchParamsObject.invoiceIds !== "undefined") {
    searchParamsObject.invoiceIds.forEach((jobGroupId) => {
      searchParams.push({
        key: "invoiceIds",
        value: String(jobGroupId),
      });
    });
  }

  if (typeof searchParamsObject.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(searchParamsObject.limit),
    });
  }

  if (typeof searchParamsObject.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(searchParamsObject.offset),
    });
  }

  return searchParams;
});

export default notificationsLogApi;
