import createTsdApi from "../../../client-api/createTsdApi";
import {
  ScheduledTask,
  ScheduledTaskCreateParams,
  ScheduledTaskQueryParams,
  ScheduledTaskUpdateParams,
} from "../../../entities/ScheduledTask/scheduledTask";
import { TsdSearchParam } from "../fetch";

const scheduledTaskApi = createTsdApi<
  ScheduledTask,
  ScheduledTaskQueryParams,
  ScheduledTaskCreateParams,
  ScheduledTaskUpdateParams
>("scheduled-tasks", (params) => {
  const search: TsdSearchParam<ScheduledTaskQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
    types: (() => {
      if (typeof params.types !== "undefined") {
        params.types.forEach((type) =>
          search.push({ key: "types", value: type })
        );
      }
    })(),
    isScheduled: (() => {
      if (typeof params.isScheduled !== "undefined") {
        search.push({
          key: "isScheduled",
          value: params.isScheduled ? "1" : "0",
        });
      }
    })(),
    scheduledForLte: (() => {
      if (typeof params.scheduledForLte !== "undefined") {
        search.push({
          key: "scheduledForLte",
          value: params.scheduledForLte,
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ScheduledTaskQueryParams>]: void | undefined;
  };

  return search;
});

export default scheduledTaskApi;
