import { Buffer } from "buffer";

const getLoginUrl = () => {
  // Base 64 encode the current url
  const base64Url = Buffer.from(window.location.href)
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");

  // Build the login URL
  let url = new URL(import.meta.env.VITE_TSD_ACCOUNTS_LOGIN);
  url.searchParams.set("successRedirect", base64Url);

  return url.href;
};

export default getLoginUrl;
