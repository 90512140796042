import React, { Suspense } from "react";

// @ts-ignore
window.TSD_API_SERVER_BASE = import.meta.env.VITE_TSD_API_SERVER_BASE;

const BookingsRouter = React.lazy(() => import("routers/BookingsRouter"));
const AccountsRouter = React.lazy(() => import("routers/AccountsRouter"));

const App = () => {
  return (
    <Suspense>
      {(() => {
        switch (window.location.hostname) {
          case import.meta.env.VITE_TSD_BOOKINGS_HOST:
            return <BookingsRouter />;
          case import.meta.env.VITE_TSD_ACCOUNTS_HOST:
          case import.meta.env.VITE_TSD_PURCHASING_HOST:
            return <AccountsRouter />;
          // case import.meta.env.VITE_TSD_PURCHASING_HOST:
          //   return (
          //     <React.Fragment>
          //       <React.Fragment>
          //         <ThemeProvider theme={theme}>
          //           <MuiPickersUtilsProvider utils={MomentUtils}>
          //             <CssBaseline />
          //             <BrowserRouter>
          //               <Routes></Routes>
          //             </BrowserRouter>
          //           </MuiPickersUtilsProvider>
          //         </ThemeProvider>
          //       </React.Fragment>
          //     </React.Fragment>
          //   );
          // case import.meta.env.VITE_TSD_DEV_HOST:
          //   return (
          //     <React.Fragment>
          //       <React.Fragment>
          //         <ThemeProvider theme={theme}>
          //           <MuiPickersUtilsProvider utils={MomentUtils}>
          //             <CssBaseline />
          //             <BrowserRouter>
          //               <Routes></Routes>
          //             </BrowserRouter>
          //           </MuiPickersUtilsProvider>
          //         </ThemeProvider>
          //       </React.Fragment>
          //     </React.Fragment>
          //   );
          default:
            return (
              <React.Fragment>
                <h1>Invalid host</h1>
              </React.Fragment>
            );
        }
      })()}
    </Suspense>
  );
};

export default App;
