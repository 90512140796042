import getLoginUrl from "../utils/getLoginUrl";

type HandleFunc = <T extends Response>(response: T) => Promise<void>;

const unauthenticated: HandleFunc = async () => {
  // Redirect
  window.location.href = getLoginUrl();
  throw new Error("Unauthenticated");
};

// TODO: Implement snackbars on all errors
const unauthorised: HandleFunc = async () => {
  throw new Error(
    "Unauthorised - please login to an account with the correct privileges."
  );
};

const notFound: HandleFunc = async (response) => {
  throw new Error(
    `Not found - the requested resource (${response.url}) was not found.`
  );
};

const serverError: HandleFunc = async (res) => {
  const json = await res.json();

  let message = "Server Error";
  if (json.message !== "" && typeof json.message !== "undefined")
    ({ message } = json);

  throw new Error(message);
};

const badRequest: HandleFunc = async (res) => {
  try {
    const json = await res.json();
    const msg = json.message;
    throw new Error(typeof msg !== "undefined" ? msg : "Bad request");
  } catch (e) {
    if (e instanceof Error) throw new Error(e.message ?? res.statusText);
    else throw e;
  }
};

const errorMatchers: {
  status: RegExp;
  handle: HandleFunc;
}[] = [
  {
    status: /^400$/,
    handle: badRequest,
  },
  {
    status: /^401$/,
    handle: unauthenticated,
  },
  {
    status: /^403$/,
    handle: unauthorised,
  },
  {
    status: /^404$/,
    handle: notFound,
  },
  {
    status: /^5[0-9]{2}$/,
    handle: serverError,
  },
];

const handleFetchErrors: <T extends Response>(
  response: T
) => Promise<T> = async (response) => {
  const errorMatcher = errorMatchers.find((errorMatcher) =>
    errorMatcher.status.test(response.status.toString())
  );

  if (errorMatcher) await errorMatcher.handle(response);

  return response;
};

export default handleFetchErrors;
