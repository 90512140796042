import {
  Remittance,
  RemittanceCreateParams,
  RemittanceQueryParams,
  RemittanceUpdateParams,
} from "../../../entities/Remittance/remittance";
import createTsdApi from "../../../client-api/createTsdApi";

import { TsdSearchParam } from "../fetch";

const remittanceApi = createTsdApi<
  Remittance,
  RemittanceQueryParams,
  RemittanceCreateParams,
  RemittanceUpdateParams
>("remittances", (searchParamsObject) => {
  let searchParams: TsdSearchParam<RemittanceQueryParams>[] = [];

  ({
    payeeOrgId: (() => {
      if (typeof searchParamsObject.payeeOrgId !== "undefined") {
        searchParams.push({
          key: "payeeOrgId",
          value: String(searchParamsObject.payeeOrgId),
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    status: (() => {
      if (typeof searchParamsObject.status !== "undefined") {
        searchParams.push({
          key: "status",
          value: searchParamsObject.status,
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<RemittanceQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default remittanceApi;
