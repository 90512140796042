import createTsdApi from "../../../client-api/createTsdApi";
import {
  CustomerEnabledFeature,
  CustomerEnabledFeatureCreateParams,
  CustomerEnabledFeatureQueryParams,
  CustomerEnabledFeatureUpdateParams,
} from "../../../entities/CustomerEnabledFeature/customerEnabledFeature";
import { TsdSearchParam } from "../fetch";

const customerEnabledFeatureApi = createTsdApi<
  CustomerEnabledFeature,
  CustomerEnabledFeatureQueryParams,
  CustomerEnabledFeatureCreateParams,
  CustomerEnabledFeatureUpdateParams
>("customer-enabled-features", (params) => {
  const search: TsdSearchParam<CustomerEnabledFeatureQueryParams>[] = [];

  ({
    customerIds: (() => {
      if (typeof params.customerIds !== "undefined") {
        params.customerIds.forEach((id) => {
          search.push({
            key: "customerIds",
            value: String(id),
          });
        });
      }
    })(),
    featureIds: (() => {
      if (typeof params.featureIds !== "undefined") {
        params.featureIds.forEach((id) => {
          search.push({
            key: "featureIds",
            value: String(id),
          });
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<CustomerEnabledFeatureQueryParams>]:
      | void
      | undefined;
  };

  return search;
});

export default customerEnabledFeatureApi;
