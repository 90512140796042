import { sessionApi } from "@tsdir/common/client-api/tsd-api";
import getLoggedInUser from "./getLoggedInUser";

const login = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const data = await sessionApi
    .newCreate({
      username: username,
      password: password,
    })
    .then((res) => {
      if (!res.ok) throw new Error("Missing details.");
      return res;
    })
    .then((res) => res.json())
    .then((res) => {
      if (!res.jwt || !res.phpSession) throw new Error("Invalid response.");
      return res;
    });

  return getLoggedInUser(data.jwt);
};

export default login;
