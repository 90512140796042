import tsdFetch, { IMapFunction, ITsdFetch, TsdSearchParam } from "../fetch";
import { Product, ProductQueryParams } from "../../../entities/product";
import dayjs from "dayjs";
import fromTsdDateFormat from "../../../helpers/dayjs-custom-plugins/fromTsdDateFormat";

// workaround for dayjs not working properly on production
// due to a faulty check in the dayjs source
dayjs.extend((a, b, c) => fromTsdDateFormat(a, b, c));

const mapParamsToSearchParams: IMapFunction<ProductQueryParams> = (
  searchParamsObject
) => {
  let searchParams: TsdSearchParam<ProductQueryParams>[] = [];

  ({
    includeAvailableStock: (() => {
      if (typeof searchParamsObject.includeAvailableStock !== "undefined") {
        searchParams.push({
          key: "includeAvailableStock",
          value: searchParamsObject.includeAvailableStock ? "1" : "0",
        });
      }
    })(),
    status: (() => {
      if (typeof searchParamsObject.status !== "undefined") {
        searchParams.push({
          key: "status",
          value: searchParamsObject.status,
        });
      }
    })(),
    isActiveAndCurrent: (() => {
      if (typeof searchParamsObject.isActiveAndCurrent !== "undefined") {
        searchParams.push({
          key: "isActiveAndCurrent",
          value: searchParamsObject.isActiveAndCurrent ? "1" : "0",
        });
      }
    })(),
    brand: (() => {
      if (typeof searchParamsObject.brand !== "undefined") {
        searchParams.push({
          key: "brand",
          value: searchParamsObject.brand,
        });
      }
    })(),
    code: (() => {
      if (typeof searchParamsObject.code !== "undefined") {
        searchParams.push({
          key: "code",
          value: searchParamsObject.code,
        });
      }
    })(),
    query: (() => {
      if (typeof searchParamsObject.query !== "undefined") {
        searchParams.push({
          key: "query",
          value: searchParamsObject.query,
        });
      }
    })(),
    codeSearch: (() => {
      if (typeof searchParamsObject.codeSearch !== "undefined") {
        searchParams.push({
          key: "codeSearch",
          value: searchParamsObject.codeSearch,
        });
      }
    })(),
    supplierIds: (() => {
      if (typeof searchParamsObject.supplierIds !== "undefined") {
        searchParamsObject.supplierIds.forEach((id) => {
          searchParams.push({
            key: "supplierIds",
            value: String(id),
          });
        });
      }
    })(),
    priceFileIds: (() => {
      if (typeof searchParamsObject.priceFileIds !== "undefined") {
        searchParamsObject.priceFileIds.forEach((id) => {
          searchParams.push({
            key: "priceFileIds",
            value: String(id),
          });
        });
      }
    })(),
    originalIds: (() => {
      if (typeof searchParamsObject.originalIds !== "undefined") {
        searchParamsObject.originalIds.forEach((id) => {
          searchParams.push({
            key: "originalIds",
            value: String(id),
          });
        });
      }
    })(),
    priceGte: (() => {
      if (typeof searchParamsObject.priceGte !== "undefined") {
        searchParams.push({
          key: "priceGte",
          value: String(searchParamsObject.priceGte),
        });
      }
    })(),
    priceLte: (() => {
      if (typeof searchParamsObject.priceLte !== "undefined") {
        searchParams.push({
          key: "priceLte",
          value: String(searchParamsObject.priceLte),
        });
      }
    })(),
    productImageIds: (() => {
      if (typeof searchParamsObject.productImageIds !== "undefined") {
        searchParamsObject.productImageIds.forEach((id) => {
          searchParams.push({
            key: "productImageIds",
            value: String(id),
          });
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    sortBy: (() => {
      if (typeof searchParamsObject.sortBy !== "undefined") {
        searchParams.push({
          key: "sortBy",
          value: String(searchParamsObject.sortBy),
        });
      }
    })(),
    cursorLastCode: (() => {
      if (typeof searchParamsObject.cursorLastCode !== "undefined") {
        searchParams.push({
          key: "cursorLastCode",
          value: String(searchParamsObject.cursorLastCode),
        });
      }
    })(),
    cursorLastName: (() => {
      if (typeof searchParamsObject.cursorLastName !== "undefined") {
        searchParams.push({
          key: "cursorLastName",
          value: String(searchParamsObject.cursorLastName),
        });
      }
    })(),
    cursorLastParentId: (() => {
      if (typeof searchParamsObject.cursorLastParentId !== "undefined") {
        searchParams.push({
          key: "cursorLastParentId",
          value: String(searchParamsObject.cursorLastParentId),
        });
      }
    })(),
    cursorLastId: (() => {
      if (typeof searchParamsObject.cursorLastId !== "undefined") {
        searchParams.push({
          key: "cursorLastId",
          value: String(searchParamsObject.cursorLastId),
        });
      }
    })(),
    parentIds: (() => {
      if (typeof searchParamsObject.parentIds !== "undefined") {
        searchParamsObject.parentIds.forEach((id) => {
          searchParams.push({ key: "parentIds", value: String(id) });
        });
      }
    })(),
    hasChildren: (() => {
      if (typeof searchParamsObject.hasChildren !== "undefined") {
        searchParams.push({
          key: "hasChildren",
          value: searchParamsObject.hasChildren ? "1" : "0",
        });
      }
    })(),
    categoryIds: (() => {
      if (typeof searchParamsObject.categoryIds !== "undefined") {
        searchParamsObject.categoryIds.forEach((id) => {
          searchParams.push({
            key: "categoryIds",
            value: String(id),
          });
        });
      }
    })(),
    subCategoryIds: (() => {
      if (typeof searchParamsObject.subCategoryIds !== "undefined") {
        searchParamsObject.subCategoryIds.forEach((id) => {
          searchParams.push({
            key: "subCategoryIds",
            value: String(id),
          });
        });
      }
    })(),
    ids: (() => {
      if (typeof searchParamsObject.ids !== "undefined") {
        searchParamsObject.ids.forEach((id) => {
          searchParams.push({
            key: "ids",
            value: String(id),
          });
        });
      }
    })(),
    activeDateGte: (() => {
      if (typeof searchParamsObject.activeDateGte !== "undefined") {
        searchParams.push({
          key: "activeDateGte",
          value: dayjs()
            .fromTsdDateFormat(searchParamsObject.activeDateGte)
            .format("YYYY-MM-DD"),
        });
      }
    })(),
    "favourites.customerIds": (() => {
      if (typeof searchParamsObject["favourites.customerIds"] !== "undefined") {
        searchParamsObject["favourites.customerIds"].forEach((id) => {
          searchParams.push({
            key: "favourites.customerIds",
            value: String(id),
          });
        });
      }
    })(),
    "favourites.isOnlyNonSiteFavouritesReturned": (() => {
      if (
        typeof searchParamsObject[
          "favourites.isOnlyNonSiteFavouritesReturned"
        ] !== "undefined"
      ) {
        searchParams.push({
          key: "favourites.isOnlyNonSiteFavouritesReturned",
          value: searchParamsObject[
            "favourites.isOnlyNonSiteFavouritesReturned"
          ]
            ? "1"
            : "0",
        });
      }
    })(),
    "favourites.isOnlySiteFavouritesReturned": (() => {
      if (
        typeof searchParamsObject["favourites.isOnlySiteFavouritesReturned"] !==
        "undefined"
      ) {
        searchParams.push({
          key: "favourites.isOnlySiteFavouritesReturned",
          value: searchParamsObject["favourites.isOnlySiteFavouritesReturned"]
            ? "1"
            : "0",
        });
      }
    })(),
    customerIdsWithAccess: (() => {
      if (typeof searchParamsObject["customerIdsWithAccess"] !== "undefined") {
        searchParamsObject["customerIdsWithAccess"]
          .map(String)
          .forEach((customerIdWithAccess) => {
            searchParams.push({
              key: "customerIdsWithAccess",
              value: customerIdWithAccess,
            });
          });
      }
    })(),
    isHiddenFromCatalogueShown: (() => {
      if (
        typeof searchParamsObject.isHiddenFromCatalogueShown !== "undefined"
      ) {
        searchParams.push({
          key: "isHiddenFromCatalogueShown",
          value: searchParamsObject.isHiddenFromCatalogueShown ? "1" : "0",
        });
      }
    })(),
    hasDatasheet: (() => {
      if (typeof searchParamsObject.hasDatasheet !== "undefined") {
        searchParams.push({
          key: "hasDatasheet",
          value: searchParamsObject.hasDatasheet ? "1" : "0",
        });
      }
    })(),
    isCurrentOrFuture: (() => {
      if (typeof searchParamsObject.isCurrentOrFuture !== "undefined") {
        searchParams.push({
          key: "isCurrentOrFuture",
          value: searchParamsObject.isCurrentOrFuture ? "1" : "0",
        });
      }
    })(),
    hasImage: (() => {
      if (typeof searchParamsObject.hasImage !== "undefined") {
        searchParams.push({
          key: "hasImage",
          value: searchParamsObject.hasImage ? "1" : "0",
        });
      }
    })(),
    isSupplierArchived: (() => {
      if (typeof searchParamsObject.isSupplierArchived !== "undefined") {
        searchParams.push({
          key: "isSupplierArchived",
          value: searchParamsObject.isSupplierArchived ? "1" : "0",
        });
      }
    })(),
    exactMatchSearch: (() => {
      if (typeof searchParamsObject.exactMatchSearch !== "undefined") {
        searchParams.push({
          key: "exactMatchSearch",
          value: searchParamsObject.exactMatchSearch ? "1" : "0",
        });
      }
    })(),
    codes: (() => {
      if (typeof searchParamsObject.codes !== "undefined") {
        searchParamsObject.codes.forEach((code) => {
          searchParams.push({
            key: "codes",
            value: String(code),
          });
        });
      }
    })(),
    createdByUserId: (() => {
      if (typeof searchParamsObject.createdByUserId !== "undefined") {
        searchParams.push({
          key: "createdByUserId",
          value: String(searchParamsObject.createdByUserId),
        });
      }
    })(),
    isStockTrackingDisabled: (() => {
      if (typeof searchParamsObject.isStockTrackingDisabled !== "undefined") {
        searchParams.push({
          key: "isStockTrackingDisabled",
          value: searchParamsObject.isStockTrackingDisabled ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ProductQueryParams>]: void | undefined;
  };

  return searchParams;
};

type QueryFunc = (parameters: ProductQueryParams) => ITsdFetch<Product[]>;

/**
 * Query server for products
 *
 * Example: get products with brand 'Brakes'
 *
 * ```js
 * query({
 *   brand: 'Brakes'
 * }).then((products) => {
 *   // products array
 * });
 * ```
 * @param parameters
 * @returns
 */
const query: QueryFunc = async (parameters) => {
  return tsdFetch(
    "api/v1/products",
    { method: "GET" },
    mapParamsToSearchParams(parameters)
  );
};

export default query;
