import createTsdApi from "../../../client-api/createTsdApi";
import {
  ProductFavouriteDeletionLog,
  ProductFavouriteDeletionLogCreateParams,
  ProductFavouriteDeletionLogQueryParams,
  ProductFavouriteDeletionLogUpdateParams,
} from "../../../entities/ProductFavouriteDeletionLog/productFavouriteDeletionLog";
import { TsdSearchParam } from "../fetch";

const productFavouriteDeletionLogApi = createTsdApi<
  ProductFavouriteDeletionLog,
  ProductFavouriteDeletionLogQueryParams,
  ProductFavouriteDeletionLogCreateParams,
  ProductFavouriteDeletionLogUpdateParams
>("product-favourite-deletion-logs", (params) => {
  const search: TsdSearchParam<ProductFavouriteDeletionLogQueryParams>[] = [];

  ({
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) =>
          search.push({ key: "ids", value: String(id) })
        );
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
    orgIds: (() => {
      if (typeof params.orgIds !== "undefined") {
        params.orgIds.forEach((id) =>
          search.push({ key: "orgIds", value: String(id) })
        );
      }
    })(),
    productOriginalIds: (() => {
      if (typeof params.productOriginalIds !== "undefined") {
        params.productOriginalIds.forEach((id) =>
          search.push({ key: "productOriginalIds", value: String(id) })
        );
      }
    })(),
    createdGte: (() => {
      if (typeof params.createdGte !== "undefined") {
        search.push({ key: "createdGte", value: params.createdGte });
      }
    })(),
    createdLte: (() => {
      if (typeof params.createdLte !== "undefined") {
        search.push({ key: "createdLte", value: params.createdLte });
      }
    })(),
  }) satisfies {
    [key in keyof Required<ProductFavouriteDeletionLogQueryParams>]: void;
  };

  return search;
});

export default {
  get: productFavouriteDeletionLogApi.get,
  getOne: productFavouriteDeletionLogApi.getOne,
  create: productFavouriteDeletionLogApi.create,
};
