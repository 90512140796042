import createTsdApi from "../../createTsdApi";
import {
  StaffHoliday,
  StaffHolidayCreateParams,
  StaffHolidayQueryParams,
  StaffHolidayUpdateParams,
} from "../../../entities/StaffHoliday/staffHoliday";
import { TsdSearchParam } from "../fetch";

const staffHolidayApi = createTsdApi<
  StaffHoliday,
  StaffHolidayQueryParams,
  StaffHolidayCreateParams,
  StaffHolidayUpdateParams
>("staff-holidays", (searchParamsObject) => {
  let searchParams: TsdSearchParam<StaffHolidayQueryParams>[] = [];
  ({
    startDateLte: (() => {
      if (typeof searchParamsObject.startDateLte !== "undefined") {
        searchParams.push({
          key: "startDateLte",
          value: searchParamsObject.startDateLte,
        });
      }
    })(),
    startDateGte: (() => {
      if (typeof searchParamsObject.startDateGte !== "undefined") {
        searchParams.push({
          key: "startDateGte",
          value: searchParamsObject.startDateGte,
        });
      }
    })(),
    finalDateLte: (() => {
      if (typeof searchParamsObject.finalDateLte !== "undefined") {
        searchParams.push({
          key: "finalDateLte",
          value: searchParamsObject.finalDateLte,
        });
      }
    })(),
    finalDateGte: (() => {
      if (typeof searchParamsObject.finalDateGte !== "undefined") {
        searchParams.push({
          key: "finalDateGte",
          value: searchParamsObject.finalDateGte,
        });
      }
    })(),
    staffIds: (() => {
      if (typeof searchParamsObject.staffIds !== "undefined") {
        searchParamsObject.staffIds.forEach((id) =>
          searchParams.push({
            key: "staffIds",
            value: String(id),
          })
        );
      }
    })(),
    isApproved: (() => {
      if (typeof searchParamsObject.isApproved !== "undefined") {
        searchParams.push({
          key: "isApproved",
          value: searchParamsObject.isApproved ? "1" : "0",
        });
      }
    })(),
    isRejected: (() => {
      if (typeof searchParamsObject.isRejected !== "undefined") {
        searchParams.push({
          key: "isRejected",
          value: searchParamsObject.isRejected ? "1" : "0",
        });
      }
    })(),
    isPending: (() => {
      if (typeof searchParamsObject.isPending !== "undefined") {
        searchParams.push({
          key: "isPending",
          value: searchParamsObject.isPending ? "1" : "0",
        });
      }
    })(),
    sortStartDate: (() => {
      if (typeof searchParamsObject.sortStartDate !== "undefined") {
        searchParams.push({
          key: "sortStartDate",
          value: searchParamsObject.sortStartDate,
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    isStaffArchived: (() => {
      if (typeof searchParamsObject.isStaffArchived !== "undefined") {
        searchParams.push({
          key: "isStaffArchived",
          value: searchParamsObject.isStaffArchived ? "1" : "0",
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof searchParamsObject.isDeleted !== "undefined") {
        searchParams.push({
          key: "isDeleted",
          value: searchParamsObject.isDeleted ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<StaffHolidayQueryParams>]: void | undefined;
  };

  return searchParams;
});

export default staffHolidayApi;
