import createTsdApi from "../../../client-api/createTsdApi";
import {
  InterestedFormSubmission,
  InterestedFormSubmissionQueryParams,
  InterestedFormSubmissionCreateParams,
  InterestedFormSubmissionUpdateParams,
} from "../../../entities/InterestedFormSubmission/interestedFormSubmission";
import { TsdSearchParam } from "../fetch";

const interestedFormSubmissionApi = createTsdApi<
  InterestedFormSubmission,
  InterestedFormSubmissionQueryParams,
  InterestedFormSubmissionCreateParams,
  InterestedFormSubmissionUpdateParams
>("interested-form-submissions", (params) => {
  const searchParams: TsdSearchParam<InterestedFormSubmissionQueryParams>[] =
    [];

  if (typeof params.ipAddress !== "undefined") {
    searchParams.push({ key: "ipAddress", value: params.ipAddress });
  }

  if (typeof params.isDeleted !== "undefined") {
    searchParams.push({
      key: "isDeleted",
      value: params.isDeleted ? "1" : "0",
    });
  }

  if (typeof params.orderByOnSubmitted !== "undefined") {
    searchParams.push({
      key: "orderByOnSubmitted",
      value: params.orderByOnSubmitted,
    });
  }

  if (typeof params.limit !== "undefined") {
    searchParams.push({
      key: "limit",
      value: String(params.limit),
    });
  }

  if (typeof params.offset !== "undefined") {
    searchParams.push({
      key: "offset",
      value: String(params.offset),
    });
  }

  return searchParams;
});

export default interestedFormSubmissionApi;
