import dayjs from "dayjs";
import createTsdApi from "../../../client-api/createTsdApi";
import {
  BudgetDayBedCount,
  BudgetDayBedCountCreateParams,
  BudgetDayBedCountQueryParams,
  BudgetDayBedCountUpdateParams,
} from "../../../entities/BudgetDayBedCount/budgetDayBedCount";
import tsdFetch, { TsdSearchParam } from "../fetch";

const budgetDayBedCountApi = createTsdApi<
  BudgetDayBedCount,
  BudgetDayBedCountQueryParams,
  BudgetDayBedCountCreateParams,
  BudgetDayBedCountUpdateParams
>("budget-day-bed-counts", (params) => {
  const search: TsdSearchParam<BudgetDayBedCountQueryParams>[] = [];

  ({
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) =>
          search.push({ key: "ids", value: String(id) })
        );
      }
    })(),
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(params.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(params.offset),
        });
      }
    })(),
    budgetIds: (() => {
      if (typeof params.budgetIds !== "undefined") {
        params.budgetIds.forEach((id) =>
          search.push({ key: "budgetIds", value: String(id) })
        );
      }
    })(),
    dateGte: (() => {
      if (typeof params.dateGte !== "undefined") {
        search.push({
          key: "dateGte",
          value: dayjs().fromTsdDateFormat(params.dateGte).format("YYYY-MM-DD"),
        });
      }
    })(),
    dateLte: (() => {
      if (typeof params.dateLte !== "undefined") {
        search.push({
          key: "dateLte",
          value: dayjs().fromTsdDateFormat(params.dateLte).format("YYYY-MM-DD"),
        });
      }
    })(),
    isDeleted: (() => {
      if (typeof params.isDeleted !== "undefined") {
        search.push({ key: "isDeleted", value: params.isDeleted ? "1" : "0" });
      }
    })(),
    sortingOrder: (() => {
      if (typeof params.sortingOrder !== "undefined") {
        search.push({
          key: "sortingOrder",
          value: params.sortingOrder,
        });
      }
    })(),
    isAmountPerBedIncVatNull: (() => {
      if (typeof params.isAmountPerBedIncVatNull !== "undefined") {
        search.push({
          key: "isAmountPerBedIncVatNull",
          value: params.isAmountPerBedIncVatNull ? "1" : "0",
        });
      }
    })(),
    isCountNull: (() => {
      if (typeof params.isCountNull !== "undefined") {
        search.push({
          key: "isCountNull",
          value: params.isCountNull ? "1" : "0",
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<BudgetDayBedCountQueryParams>]: void | undefined;
  };

  return search;
});

const put = async (fields: BudgetDayBedCountUpdateParams) => {
  return tsdFetch(`api/v1/budget-day-bed-counts`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(fields),
  });
};

export default {
  get: budgetDayBedCountApi.get,
  getOne: budgetDayBedCountApi.getOne,
  update: budgetDayBedCountApi.update,
  put,
};
