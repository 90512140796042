import createTsdApi from "../../../client-api/createTsdApi";
import {
  SummaryData,
  SummaryDataCreateParams,
  SummaryDataQueryParams,
  SummaryDataUpdateParams,
} from "../../../entities/SummaryData/summaryData";
import { TsdSearchParam } from "../fetch";

const summaryDataApi = createTsdApi<
  SummaryData,
  SummaryDataQueryParams,
  SummaryDataCreateParams,
  SummaryDataUpdateParams
>("summary-data", (searchParamsObject) => {
  const search: TsdSearchParam<SummaryDataQueryParams>[] = [];

  ({
    ids: (() => {
      if (typeof searchParamsObject.ids !== "undefined") {
        searchParamsObject.ids.forEach((id) =>
          search.push({
            key: "ids",
            value: String(id),
          })
        );
      }
    })(),
    customerIds: (() => {
      if (typeof searchParamsObject.customerIds !== "undefined") {
        searchParamsObject.customerIds.forEach((id) =>
          search.push({
            key: "customerIds",
            value: String(id),
          })
        );
      }
    })(),
    supplierIds: (() => {
      if (typeof searchParamsObject.supplierIds !== "undefined") {
        searchParamsObject.supplierIds.forEach((id) =>
          search.push({
            key: "supplierIds",
            value: String(id),
          })
        );
      }
    })(),
    months: (() => {
      if (typeof searchParamsObject.months !== "undefined") {
        searchParamsObject.months.forEach((month) =>
          search.push({
            key: "months",
            value: String(month),
          })
        );
      }
    })(),
    years: (() => {
      if (typeof searchParamsObject.years !== "undefined") {
        searchParamsObject.years.forEach((year) =>
          search.push({
            key: "years",
            value: String(year),
          })
        );
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        search.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        search.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    yearMonths: (() => {
      if (typeof searchParamsObject.yearMonths !== "undefined") {
        searchParamsObject.yearMonths.forEach(({ year, month }) => {
          const formattedMonth = String(month).padStart(2, "0");
          const value = `${year}-${formattedMonth}`;
          search.push({
            key: "yearMonths",
            value,
          });
        });
      }
    })(),
  }) satisfies {
    [key in keyof Required<SummaryDataQueryParams>]: void | undefined;
  };
  return search;
});

export default { get: summaryDataApi.get };
