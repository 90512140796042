import createTsdApi from "../../createTsdApi";
import {
  Staff,
  StaffCreateParams,
  StaffQueryParams,
  StaffUpdateParams,
} from "../../../entities/staff";
import { TsdSearchParam } from "../fetch";

const staffApi = createTsdApi<
  Staff,
  StaffQueryParams,
  StaffCreateParams,
  StaffUpdateParams
>("staff", (searchParamsObject) => {
  let searchParams: TsdSearchParam<StaffQueryParams>[] = [];

  ({
    isArchived: (() => {
      if (typeof searchParamsObject.isArchived !== "undefined") {
        searchParams.push({
          key: "isArchived",
          value: searchParamsObject.isArchived ? "1" : "0",
        });
      }
    })(),
    approvalUserIds: (() => {
      if (typeof searchParamsObject.approvalUserIds !== "undefined") {
        searchParamsObject.approvalUserIds.forEach((id) => {
          searchParams.push({
            key: "approvalUserIds",
            value: String(id),
          });
        });
      }
    })(),
    userIds: (() => {
      if (typeof searchParamsObject.userIds !== "undefined") {
        searchParamsObject.userIds.forEach((id) => {
          searchParams.push({
            key: "userIds",
            value: String(id),
          });
        });
      }
    })(),
    name: (() => {
      if (typeof searchParamsObject.name !== "undefined") {
        searchParams.push({
          key: "name",
          value: searchParamsObject.name,
        });
      }
    })(),
    teamsIds: (() => {
      if (typeof searchParamsObject.teamsIds !== "undefined") {
        searchParamsObject.teamsIds.forEach((teamId) => {
          searchParams.push({
            key: "teamsIds",
            value: teamId,
          });
        });
      }
    })(),
    limit: (() => {
      if (typeof searchParamsObject.limit !== "undefined") {
        searchParams.push({
          key: "limit",
          value: String(searchParamsObject.limit),
        });
      }
    })(),
    offset: (() => {
      if (typeof searchParamsObject.offset !== "undefined") {
        searchParams.push({
          key: "offset",
          value: String(searchParamsObject.offset),
        });
      }
    })(),
    orderByName: (() => {
      if (typeof searchParamsObject.orderByName !== "undefined") {
        searchParams.push({
          key: "orderByName",
          value: searchParamsObject.orderByName,
        });
      }
    })(),
  }) satisfies { [key in keyof Required<StaffQueryParams>]: void | undefined };

  if (typeof searchParamsObject.approvalUserIds !== "undefined") {
    searchParamsObject.approvalUserIds.map((id) =>
      searchParams.push({ key: "approvalUserIds", value: String(id) })
    );
  }

  return searchParams;
});

export default staffApi;
