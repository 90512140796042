import createTsdApi from "../../../client-api/createTsdApi";
import {
  Category,
  CategoryCreateParams,
  CategoryQueryParams,
  CategoryUpdateParams,
} from "../../../entities/category";
import { TsdSearchParam } from "../fetch";

const categoryApi = createTsdApi<
  Category,
  CategoryQueryParams,
  CategoryCreateParams,
  CategoryUpdateParams
>("categories", (params) => {
  const search: TsdSearchParam<CategoryQueryParams>[] = [];

  ({
    limit: (() => {
      if (typeof params.limit !== "undefined") {
        search.push({ key: "limit", value: String(params.limit) });
      }
    })(),
    offset: (() => {
      if (typeof params.offset !== "undefined") {
        search.push({ key: "offset", value: String(params.offset) });
      }
    })(),
    name: (() => {
      if (typeof params.name !== "undefined") {
        search.push({ key: "name", value: params.name });
      }
    })(),
    nameSearchString: (() => {
      if (typeof params.nameSearchString !== "undefined") {
        search.push({
          key: "nameSearchString",
          value: params.nameSearchString,
        });
      }
    })(),
    notParentId: (() => {
      if (typeof params.notParentId !== "undefined") {
        search.push({ key: "notParentId", value: String(params.notParentId) });
      }
    })(),
    parentId: (() => {
      if (typeof params.parentId !== "undefined") {
        search.push({ key: "parentId", value: String(params.parentId) });
      }
    })(),
    priceFileIds: (() => {
      if (typeof params.priceFileIds !== "undefined") {
        params.priceFileIds.forEach((id) =>
          search.push({ key: "priceFileIds", value: String(id) })
        );
      }
    })(),
    status: (() => {
      if (typeof params.status !== "undefined") {
        search.push({ key: "status", value: params.status });
      }
    })(),
    ids: (() => {
      if (typeof params.ids !== "undefined") {
        params.ids.forEach((id) =>
          search.push({ key: "ids", value: String(id) })
        );
      }
    })(),
  }) satisfies {
    [key in keyof Required<CategoryQueryParams>]: void | undefined;
  };

  return search;
});

export default categoryApi;
